import { patientConstants } from 'redux/actions/types';

const initialState = {
  loading: false,

  finishedRequests: {
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path:
        'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
      per_page: 15,
      to: 1,
      total: 0,
    },
  },
  canceledRequests: {
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path:
        'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
      per_page: 15,
      to: 1,
      total: 0,
    },
  },
  sendedQuotations: {
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path:
        'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
      per_page: 15,
      to: 1,
      total: 0,
    },
  },
  bookedRequests: {
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path:
        'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
      per_page: 15,
      to: 1,
      total: 0,
    },
  },
  newRequests: {
    data: [],
    meta: {
      current_page: 1,
      from: 1,
      last_page: 1,
      path:
        'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
      per_page: 15,
      to: 1,
      total: 0,
    },
  },
  request: null,
  patientToken: null,
};

function patient(state = initialState, action) {
  switch (action.type) {
    case patientConstants.GET_NEW_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        getNewRequests: true,
      };
    case patientConstants.GET_NEW_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getNewRequests: false,
        newRequests: action.requests,
      };
    case patientConstants.GET_NEW_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.GET_BOOKED_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        getBookedRequests: true,
      };
    case patientConstants.GET_BOOKED_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getBookedRequests: false,
        bookedRequests: action.requests,
      };
    case patientConstants.GET_BOOKED_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.GET_BOOKED_AND_CANCELED_REQUEST:
      return {
        ...state,
        loading: true,
        getFinishedRequests: true,
      };
    case patientConstants.GET_BOOKED_AND_CANCELED_SUCCESS:
      return {
        ...state,
        loading: false,
        getFinishedRequests: false,
        finishedRequests: action.requests,
      };
    case patientConstants.GET_BOOKED_AND_CANCELED_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.GET_SENDED_QUOTATION_REQUEST:
      return {
        ...state,
        loading: true,
        getSendedQuotations: true,
      };
    case patientConstants.GET_SENDED_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        getSendedQuotations: false,
        sendedQuotations: action.requests,
      };
    case patientConstants.GET_SENDED_QUOTATION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.GET_CANCELED_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
        getCanceledRequests: true,
      };
    case patientConstants.GET_CANCELED_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getCanceledRequests: false,
        canceledRequests: action.requests,
      };
    case patientConstants.GET_CANCELED_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.GET_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
        getRequest: true,
      };
    case patientConstants.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        getRequest: false,
        request: action.request,
      };
    case patientConstants.GET_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.REJECT_REQUEST_REQUEST:
      return {
        ...state,
        loading: true,
        rejectRequests: true,
      };
    case patientConstants.REJECT_REQUEST_SUCCESS:
      const newRequests = state.newRequests && {
        ...state.newRequests,
        data: state.newRequests.data.filter(
          (request) => request.id !== action.id
        ),
      };
      return {
        ...state,
        loading: false,
        rejectRequests: false,
        newRequests: newRequests,
      };
    case patientConstants.REJECT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.SEND_QUOTATION_REQUEST:
      return {
        ...state,
        loading: true,
        sendQuotation: true,
      };
    case patientConstants.SEND_QUOTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        sendQuotation: false,
      };
    case patientConstants.SEND_QUOTATION_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.GENERATE_SHARE_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        generateShareToken: true,
      };
    case patientConstants.GENERATE_SHARE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        generateShareToken: false,
        patientToken: action.token,
      };
    case patientConstants.GENERATE_SHARE_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case patientConstants.CLEAR_DATA:
      return {
        loading: false,

        finishedRequests: {
          data: [],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path:
              'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
            per_page: 15,
            to: 1,
            total: 0,
          },
        },
        canceledRequests: {
          data: [],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path:
              'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
            per_page: 15,
            to: 1,
            total: 0,
          },
        },
        sendedQuotations: {
          data: [],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path:
              'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
            per_page: 15,
            to: 1,
            total: 0,
          },
        },
        bookedRequests: {
          data: [],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path:
              'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
            per_page: 15,
            to: 1,
            total: 0,
          },
        },
        newRequests: {
          data: [],
          meta: {
            current_page: 1,
            from: 1,
            last_page: 1,
            path:
              'https://api.hk.phantasm.biz/api/v1/hospital/patient-quotation-requests',
            per_page: 15,
            to: 1,
            total: 0,
          },
        },
        request: null,
        patientToken: null,
      };

    default:
      return state;
  }
}

export default patient;
