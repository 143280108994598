import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patientActions } from 'redux/actions';
import {
  H2,
  ProfilePic,
  convertDateToFullStringDate,
  PrimaryButton,
} from '@mous221/hk-utils';
import styled from '@emotion/styled';

const ViewQuotationsWrapper = styled.div`
  background: #fff;

  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;

  margin-bottom: 20px;
  display: flex;
`;

const PatientWrapper = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 18px 0 0 18px;
  .patient {
    &__img {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 10px;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
        span {
          display: block;
        }
      }
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        font-size: 24px;
        margin-top: 0;
      }
      p {
        color: #9298ac;
        font-size: 14px;
        font-weight: bold;
        margin-top: 0;
        margin-bottom: 0;

        &.preferred-date {
          font-family: AvenirNext, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: #060c2b;
          margin-top: 0;
          margin-bottom: 0;
          span {
            color: #97d7d8;
            font-weight: bold;
          }
        }
        &.request-status {
          text-align: center;
          margin: 10px;
        }
      }
    }
  }
`;

export class ViewQuotations extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    generateShareToken: PropTypes.func,
  };

  render() {
    const { request, generateShareToken } = this.props;
    console.log(
      '🚀 ~ request, generateShareToken',
      request,
      generateShareToken
    );
    const startDate = convertDateToFullStringDate(request.createdAt);
    return (
      <ViewQuotationsWrapper>
        <PatientWrapper>
          <div className="patient__img">
            <ProfilePic user={request.patient} width="50" height="50" />
            <p>
              {request.name} <span>{request.email}</span>
            </p>
          </div>
          <div className="patient__content">
            <div>
              <H2>{request.note}</H2>
              <p className="preferred-date">{request.invitationText}</p>
              <p>Sent on: {startDate}</p>
            </div>
            <div>
              {/* share  */}
              <PrimaryButton
                onClick={() => {
                  generateShareToken(request.patient.id);
                }}
              >
                <button>Share with designated team</button>
              </PrimaryButton>
              <p
                className="request-status"
                style={{ color: 'rgb(92, 179, 219)' }}
              >
                {request.status}
              </p>
            </div>
          </div>
        </PatientWrapper>
      </ViewQuotationsWrapper>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  generateShareToken: (id, sharedDate) =>
    dispatch(patientActions.generateShareToken(id, sharedDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotations);
