import React from 'react';
import PropTypes from 'prop-types';

import { Breadcrumb, H1 } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { CTScan, CTScan2X, MRI, MRI2X, XRay, XRay2X } from 'images';

const RadiologistWrapper = styled.div`
  min-height: 65vh;
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
`;
const RadiologyWrapper = styled.div`
  margin-top: 30px;
  a {
    width: 250px;
    background: #fff;
    text-align: center;
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 20px;
    border-radius: 6px;
    box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
    border: solid 1px #e4e9f2;
    &:hover {
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    }
    p {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 16px;
      font-weight: bold;
      color: #97d7d8;
    }
    .time {
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 12px;
      font-weight: normal;
      color: #9298ac;
    }
    & + a {
      margin-left: 20px;
    }
  }
`;

export default function Radiologist(props) {
  const {
    match: {
      params: { requestId, patientId, invitationId },
      path,
    },
  } = props;

  let mriUrl = '';
  let ctUrl = '';
  let xrayUrl = '';

  if (requestId && patientId) {
    if (requestId && path.includes('/request')) {
      mriUrl = `/request/${requestId}/mri/${patientId}`;
      ctUrl = `/request/${requestId}/ct/${patientId}`;
      xrayUrl = `/request/${requestId}/xrays/${patientId}`;
    } else if (invitationId && path.includes('/invited-patient')) {
      mriUrl = `/invited-patient/${invitationId}/mri/${patientId}`;
      ctUrl = `/invited-patient/${invitationId}/ct/${patientId}`;
      xrayUrl = `/invited-patient/${invitationId}/xrays/${patientId}`;
    }
  }

  return (
    <Container>
      <RadiologistWrapper>
        <Breadcrumb
          links={[{ item: 'Medical Record ', url: '/#MedicalRecord' }]}
        />
        <H1>Radiologist</H1>
        <RadiologyWrapper>
          <Link to={mriUrl}>
            <picture>
              <source srcSet={`${MRI2X} 2x`} />
              <img src={MRI} alt="MRI Scan" />
            </picture>
            <p>MRI</p>
            <p className="time">Dec 10, 2018, 03:29 PM</p>
          </Link>
          <Link to={ctUrl}>
            <picture>
              <source srcSet={`${CTScan2X} 2x`} />
              <img src={CTScan} alt="CT Scan" />
            </picture>
            <p>CT Scan</p>
            <p className="time">Dec 10, 2018, 03:29 PM</p>
          </Link>
          <Link to={xrayUrl}>
            <picture>
              <source srcSet={`${XRay2X} 2x`} />
              <img src={XRay} alt="XRay" />
            </picture>
            <p>XRay</p>
            <p className="time">Dec 10, 2018, 03:29 PM</p>
          </Link>
        </RadiologyWrapper>
      </RadiologistWrapper>
    </Container>
  );
}

Radiologist.propTypes = {
  match: PropTypes.object.isRequired,
};
