import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';
class MedicalReportComponent extends Component {
  componentDidMount() {
    const {
      getMedicalReport,

      match: {
        params: { id },
      },
    } = this.props;

    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    if (id && patientId) {
      getMedicalReport(id, patientId);
    }
  }
  render() {
    const {
      report: medicalReport,
      match: {
        params: { requestId, invitationId, id },
        path,
      },
    } = this.props;

    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      requestId && patientId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
            {
              item: 'Medical Reports',
              url: `/request/${requestId}/medical-reports/${patientId}`,
            },
          ]
        : invitationId && patientId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
            {
              item: 'Medical Reports',
              url: `/invited-patient/${invitationId}/medical-reports/${patientId}`,
            },
          ]
        : [];
    console.log('medicalReport', medicalReport);
    return (
      <Container>
        {medicalReport && medicalReport.id === Number(id) && (
          <MedicalReport
            title={medicalReport.title}
            updatedAt={medicalReport.updatedAt}
            description={medicalReport.description || medicalReport.note}
            image={{
              fileLocation: medicalReport.fileLocation,
              fileName: medicalReport.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={requestId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getMedicalReport: (id, patientId) =>
    dispatch(patientReportsActions.getMedicalReport(id, patientId)),
});

MedicalReportComponent.propTypes = {
  getMedicalReport: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalReportComponent);
