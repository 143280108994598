import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import { ConcernIcon } from 'images';
import { H1, convertDateToFullStringDate } from '@mous221/hk-utils';

const PatientConcernWrapper = styled.div`
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background: #fff;
`;

const PatientConcernHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 0;

  .concern-header {
    &__title {
      display: flex;
      align-items: center;
    }
    &__time {
      p {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
    }
  }
`;
const PatientConcernContent = styled.div`
  padding: 20px;
  .concern-content {
    &__title {
      font-size: 20px;
      font-weight: bold;
      color: #060c2b;
      margin-bottom: 7px;
    }
    &__time {
      font-size: 14.4px;
      font-weight: normal;
      color: #9298ac;
    }
    &__body {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #060c2b;
      p + p {
        color: #97d7d8;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
`;

export default class PatientConcern extends Component {
  render() {
    const { request } = this.props;

    return (
      <PatientConcernWrapper>
        <PatientConcernHeader>
          <div className="concern-header__title">
            <ConcernIcon />
            <H1>Patient Concern</H1>
          </div>
          <div className="concern-header__time"></div>
        </PatientConcernHeader>
        <PatientConcernContent>
          <h2 className="concern-content__title">
            {request.patientConsultationRequest.symptoms}
          </h2>
          <p className="concern-content__time">
            {convertDateToFullStringDate(request.updatedAt)}
          </p>
          <div className="concern-content__body">
            <p>{request.patientConsultationRequest.symptomsExplained}</p>
          </div>
        </PatientConcernContent>
      </PatientConcernWrapper>
    );
  }
}

PatientConcern.propTypes = {
  request: PropTypes.object.isRequired,
};
