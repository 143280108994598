import React from 'react';
import { Lab, Lab2X } from 'images';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { ProfilePic, convertDateToFullStringDate } from '@mous221/hk-utils';

const ActionWrapper = styled.div`
  display: flex;
  font-family: AvenirNext, Arial, sans-serif;
  background: #fff;
  padding: 12px;
  border-bottom: 1px solid #e4e9f2;
`;
const ActionIcon = styled.div`
  margin-right: 12px;
  img {
    width: 48px;
    height: 48px;
  }
`;
const ActionDetails = styled.div`
  margin-left: 12px;
  .action-details {
    &__title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__content {
      font-size: 14px;
      font-weight: 500;
      color: #9298ac;
      margin-bottom: 8px;
    }
    &__time {
      font-size: 12px;
      font-weight: normal;
      color: #9298ac;
      margin-bottom: 15px;
    }
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  p {
    margin-bottom: 0;
    line-height: 24px;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }
`;

export default function Action(props) {
  const { text, time, user } = props;
  return (
    <ActionWrapper className="action-wrapper">
      <ActionIcon>
        <picture>
          <source srcSet={`${Lab2X} 2x`} />
          <img src={Lab} alt="Lab" />
        </picture>
      </ActionIcon>
      <ActionDetails>
        <h4 className="action-details__title">{text}</h4>
        {/* <p className="action-details__content">{text}</p> */}
        <p className="action-details__time">
          {convertDateToFullStringDate(time)}
        </p>
        <ImageWrapper>
          <ProfilePic user={user} />
          <p>{user.name}</p>
        </ImageWrapper>
      </ActionDetails>
    </ActionWrapper>
  );
}

Action.propTypes = {
  text: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
};
