import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Breadcrumb, H1, Pagination, StateCard } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';

const RadiologiesWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class Radiologies extends Component {
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
      getSharedRadiologies,
    } = this.props;

    if (patientId) {
      getSharedRadiologies(patientId);
    }
  }

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedRadiologies,
    } = this.props;

    if (patientId) {
      getSharedRadiologies(patientId, null, page);
    }
  };

  render() {
    const {
      reports: radiologies,
      match: {
        params: { requestId, patientId, invitationId },
        path,
      },
    } = this.props;
    const BreadcrumbLinks =
      requestId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
          ]
        : invitationId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}`,
            },
          ]
        : [];

    return (
      <Container>
        <RadiologiesWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Radiologist</H1>
          {radiologies &&
            radiologies.data?.map((report) => (
              <StateCard
                location={report.viewer_url}
                key={report.updated_at}
                title={report.title}
                time={report.updated_at}
                patientId={patientId}
                item={report}
              />
            ))}
          {radiologies &&
            radiologies.meta?.total > radiologies.meta?.per_page && (
              <Pagination
                pageSize={radiologies.meta?.per_page}
                total={radiologies.meta?.total}
                setPage={this.setPage}
              />
            )}
        </RadiologiesWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedRadiologies: (patientId, sharedToken, page = null) =>
    dispatch(
      patientReportsActions.getSharedRadiologies(patientId, sharedToken, page)
    ),
});

Radiologies.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedRadiologies: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Radiologies);
