import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';
class ECG extends Component {
  componentDidMount() {
    const {
      getECG,
      match: {
        params: { id },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (id && patientId) {
      getECG(id, patientId);
    }
  }
  render() {
    const {
      report: ecg,
      match: {
        params: { requestId, invitationId, id },
        path,
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      requestId && patientId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
            {
              item: 'ECGS',
              url: `/request/${requestId}/ecgs/${patientId}`,
            },
          ]
        : invitationId && patientId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
            {
              item: 'ECGS',
              url: `/invited-patient/${invitationId}/ecgs/${patientId}`,
            },
          ]
        : [];

    return (
      <Container>
        {ecg && ecg.id === Number(id) && (
          <MedicalReport
            title={ecg.value}
            updatedAt={ecg.updatedAt}
            description={ecg.note || ecg.description}
            image={{
              fileLocation: ecg.fileLocation,
              fileName: ecg.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={requestId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getECG: (d, patientId) =>
    dispatch(patientReportsActions.getECG(d, patientId)),
});

ECG.propTypes = {
  getECG: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECG);
