import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { patientReportsActions } from 'redux/actions';
import { Breadcrumb, H1, Pagination, StateCard } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';

const ECGsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class ECGs extends Component {
  componentDidMount() {
    const {
      getSharedECGs,
      match: {
        params: { patientId },
      },
    } = this.props;

    if (patientId) {
      getSharedECGs(patientId);
    }
  }

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedECGs,
    } = this.props;

    if (patientId) {
      getSharedECGs(patientId, null, page);
    }
  };

  render() {
    const {
      reports: ecgs,
      match: {
        params: { requestId, patientId, invitationId },
        path,
      },
    } = this.props;

    const BreadcrumbLinks =
      requestId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
          ]
        : invitationId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
          ]
        : [];
    const url = (reportId) =>
      requestId && path.includes('/request')
        ? `/request/${requestId}/ecg/${reportId}?patientId=${patientId}`
        : invitationId && path.includes('/invited-patient')
        ? `/invited-patient/${invitationId}/ecg/${reportId}?patientId=${patientId}`
        : `#!`;
    return (
      <Container>
        <ECGsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>ECG</H1>

          {ecgs &&
            ecgs.data?.map((report) => (
              <StateCard
                to={url(report.id)}
                key={report.createdAt}
                title={report.title}
                time={report.createdAt}
              />
            ))}
          {ecgs && ecgs.meta?.total > ecgs.meta?.per_page && (
            <Pagination
              pageSize={ecgs.meta?.per_page}
              total={ecgs.meta?.total}
              setPage={this.setPage}
            />
          )}
        </ECGsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedECGs: (patientId, sharedToken, page = null) =>
    dispatch(patientReportsActions.getSharedECGs(patientId, sharedToken, page)),
});

ECGs.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedECGs: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECGs);
