import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Readings } from '@mous221/hk-components';
import { patientReportsActions } from 'redux/actions';

export class ReadingsComponent extends Component {
  static propTypes = {
    getReadings: PropTypes.func.isRequired,
    readings: PropTypes.array,
    patientId: PropTypes.number.isRequired,
  };
  componentDidMount() {
    const { patientId, getReadings } = this.props;

    getReadings(patientId);
  }

  render() {
    const { readings } = this.props;
    return (
      <div>
        {readings && readings.length > 0 && (
          <Readings readings={readings} blockConsultant={true} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { readings } = state.patientReports;

  return {
    readings,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getReadings: (patientId, token = null) =>
    dispatch(patientReportsActions.getReadings(patientId, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReadingsComponent);
