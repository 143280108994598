import React from 'react';
import { H2, PrimaryButton } from '@mous221/hk-utils';
import { Link } from 'react-router-dom';
import { HospitalIcon } from 'images';
import styled from '@emotion/styled';

const EmptyPatientWrapper = styled.div`
  text-align: center;
  h2 {
    font-size: 35px;
  }
`;

export default function EmptyPatient() {
  return (
    <EmptyPatientWrapper>
      <div>
        <HospitalIcon />
        <H2>There are no new hospital requests</H2>
        <p>
          Please check back later. When a patient makes a request, you will
          receive a notification.
        </p>
      </div>
      <PrimaryButton>
        <Link to="/invite-patient">Invite Patient</Link>
      </PrimaryButton>
    </EmptyPatientWrapper>
  );
}
