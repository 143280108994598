import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import XRayComponent from './XRay';

function XRayPage(props) {
  return <MainLayout footer="main" children={<XRayComponent {...props} />} />;
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(XRayPage);
