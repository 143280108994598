import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Form, Input, Select } from 'antd';
import 'antd/lib/form/style/css';
import PropTypes from 'prop-types';

import { FormWrapper } from '@mous221/hk-components';

const FormItem = Form.Item;
const Option = Select.Option;

const formValues = {
  condition: {
    title: 'Briefly tell us about your Condition',
    notes: 'Describe in as much detail as you can.',
  },
  medical_report: {
    title: 'Briefly tell us about your Medical Report',
    notes: 'Describe in as much detail as you can.',
  },
  reading: {
    title: 'Provide us with your medical readings',
    notes: '',
  },
  ecg: {
    title: 'Briefly tell us about your ECG',
    notes: 'Describe in as much detail as you can.',
  },
  allergy: {
    title: 'Briefly tell us about an Allergy you have',
    notes: 'Describe in as much detail as you can.',
  },
  'radiology-ct': {
    title: 'Briefly tell us about your MRI/CT Scan',
    notes: 'Describe in as much detail as you can',
  },
  'radiology-xray': {
    title: 'Briefly tell us about your X-Ray',
    notes: 'Describe in as much detail as you can.',
  },
  lab_result: {
    title: 'Briefly tell us about your Lab Result',
    notes: 'Describe in as much detail as you can.',
  },
};

const SubmitButton = styled.button`
  width: 100%;
  background: #000;
  color: #fff;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  font-family: Heebo;
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
  padding: 10px 0;
  @media (max-width: 767px) {
    margin-top: 15px;
    margin-bottom: 0;
  }
`;

const RequestMedicalRecordFormWrapper = styled.div`
  .text-area {
    margin-top: 15px;
    height: 150px;
    margin-bottom: 15px;
  }
  .Form-wrapper {
    border: none;
  }
  .ant-select {
    margin-bottom: 15px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-select-search__field {
    border none !important;
    
  }
  .ant-select-selection--multiple {
    height: auto !important;
  }
  
`;

class RequestMedicalRecordForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      requestScan,
      consultationId,
      scanType,
      handelCloseModal,
      patientId,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        requestScan({
          ...values,
          patient_id: patientId ? patientId : null,
          scanType: scanType
            ? scanType === 'reading'
              ? values.scanType
              : scanType
            : values.scanType,
          scan_type: scanType ? scanType : values.scanType,
          id: consultationId ? consultationId : null,
          title: values.scanName,
          body: values.notes,
        });
        handelCloseModal();
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
      // showTypes,
      scanType,
      scan,
    } = this.props;
    console.log('🚀 ~ scan', scan, scanType);

    // const scanTypes = [
    //   { value: 'radiology-ct', title: 'CT' },
    //   { value: 'radiology-xray', title: 'X-ray' },
    //   { value: 'radiology-mri', title: 'MRI' },
    //   { value: 'ecg', title: 'ECG' },
    //   { value: 'medical_report', title: 'Medical report' },
    //   { value: 'allergy', title: 'Allergy' },
    //   { value: 'condition', title: 'Condition' },
    //   { value: 'lab_result', title: 'Label result' },
    //   { value: 'reading-blood-pressure', title: 'Blood pressure' },
    //   { value: 'reading-blood-sugar', title: 'Blood sugar' },
    //   { value: 'reading-weight', title: 'Weight' },
    //   { value: 'reading-height', title: 'Height' },
    //   { value: 'reading-heart-rate', title: 'Heart rate' },
    //   { value: 'reading-temperature', title: 'Temperature' },
    // ];
    const readingsTypes = [
      { value: 'reading-blood-pressure', title: 'Blood pressure' },
      { value: 'reading-blood-sugar', title: 'Blood sugar' },
      { value: 'reading-weight', title: 'Weight' },
      { value: 'reading-height', title: 'Height' },
      { value: 'reading-heart-rate', title: 'Heart rate' },
      { value: 'reading-temperature', title: 'Temperature' },
    ];
    return (
      <RequestMedicalRecordFormWrapper>
        <FormWrapper>
          <Form onSubmit={this.handleSubmit}>
            {scanType === 'reading' && (
              <FormItem>
                {getFieldDecorator('scanType', {
                  initialValue:
                    scan && Array.isArray(scan.scanType) && scan.scanType
                      ? scan.scanType
                      : [],
                })(
                  <Select mode="multiple" placeholder="Scan Type">
                    {readingsTypes &&
                      readingsTypes.map((type) => (
                        <Option
                          key={`readingsTypes-${type.value}`}
                          value={type.value}
                        >
                          {type.title}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            )}
            <FormItem>
              {getFieldDecorator('scanName', {
                initialValue: formValues[scanType].title,
              })(<Input placeholder="Add request title" />)}
            </FormItem>
            <FormItem>
              {getFieldDecorator('notes', {
                initialValue: formValues[scanType].notes,
              })(<Input.TextArea placeholder="Notes" className="text-area" />)}
            </FormItem>
            <FormItem>
              <SubmitButton className="submit-button" htmlType="submit">
                Save
              </SubmitButton>
            </FormItem>
          </Form>
        </FormWrapper>
      </RequestMedicalRecordFormWrapper>
    );
  }
}

export default Form.create({})(RequestMedicalRecordForm);
RequestMedicalRecordForm.propTypes = {
  form: PropTypes.object.isRequired,
  requestScan: PropTypes.func.isRequired,
  scan: PropTypes.object,
  consultationId: PropTypes.number,
  scanType: PropTypes.string,
  handelCloseModal: PropTypes.func.isRequired,
  showTypes: PropTypes.bool,
  showPrice: PropTypes.bool,
  patientId: PropTypes.number,
};
