/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const CloseIcon = ({ color, width, height }) => {
  return (
    <svg
      height={`${height || '30'}px`}
      width={`${width || '30'}px`}
      viewBox="0 0 30 30"
      css={css`
        fill: ${color || '#ec0707'};
        max-width: ${width || '30'}px;
        max-height: ${height || '30'}px;
        height: ${height || '30'}px;
        width: ${width || '30'}px;
      `}
    >
      <g id="surface1">
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
            fill: 'rgb(92.54902%,2.745098%,2.745098%)',
          }}
          d="M 30 15 C 30 23.285156 23.285156 30 15 30 C 6.714844 30 0 23.285156 0 15 C 0 6.714844 6.714844 0 15 0 C 23.285156 0 30 6.714844 30 15 Z M 30 15 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
            fill: 'rgb(92.54902%,2.745098%,2.745098%)',
          }}
          d="M 30 15 C 30 23.25 23.25 30 15 30 C 10.21875 30 6 27.84375 3.28125 24.375 C 5.8125 26.4375 9.09375 27.65625 12.65625 27.65625 C 20.90625 27.65625 27.65625 20.90625 27.65625 12.65625 C 27.65625 9.09375 26.4375 5.8125 24.375 3.28125 C 27.84375 6 30 10.21875 30 15 Z M 30 15 "
        />
        <path
          style={{
            stroke: 'none',
            fillRule: 'nonzero',
            fillOpacity: 1,
            fill: 'rgb(100%,100%,100%)',
          }}
          d="M 21.84375 21.84375 C 21.28125 22.40625 20.34375 22.40625 19.78125 21.84375 L 15 17.0625 L 10.21875 21.84375 C 9.65625 22.40625 8.71875 22.40625 8.15625 21.84375 C 7.59375 21.28125 7.59375 20.34375 8.15625 19.78125 L 12.9375 15 L 8.15625 10.21875 C 7.59375 9.65625 7.59375 8.71875 8.15625 8.15625 C 8.71875 7.59375 9.65625 7.59375 10.21875 8.15625 L 15 12.9375 L 19.78125 8.15625 C 20.34375 7.59375 21.28125 7.59375 21.84375 8.15625 C 22.40625 8.71875 22.40625 9.65625 21.84375 10.21875 L 17.0625 15 L 21.84375 19.78125 C 22.40625 20.34375 22.40625 21.28125 21.84375 21.84375 Z M 21.84375 21.84375 "
        />
      </g>
    </svg>
  );
};

export default CloseIcon;

CloseIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
