import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';

import { MainLayout } from '@mous221/hk-components';
import { HomePage } from './components';

const Home = (props) => (
  <MainLayout footer="main" children={<HomePage {...props} />} {...props} />
);
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(Home);
