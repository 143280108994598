import React from 'react';
import { H1 } from '@mous221/hk-utils';
import { Action } from './';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const ActivityWrapper = styled.div`
  margin-bottom: 10px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  .action-wrapper:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

const ActivityHeader = styled.div`
  padding: 18px;
  background-color: #f9fafc;
  border-radius: 6px 6px 0 0;
  h1 {
    font-size: 18px;
  }
`;

export default function Activity(props) {
  const { activity } = props;
  const actions =
    activity &&
    activity.data.slice(0, 3).map((action) => {
      return (
        <Action
          text={action.activity_text}
          key={action.created_at}
          time={action.created_at}
          user={action.user}
        />
      );
    });
  return (
    <ActivityWrapper>
      <ActivityHeader>
        <H1>Activity</H1>
      </ActivityHeader>
      {actions}
    </ActivityWrapper>
  );
}

Activity.propTypes = {
  activity: PropTypes.object,
};
