import React, { Component } from 'react';
import { Row, Col, Spin } from 'antd';
import styled from '@emotion/styled/macro';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { patientActions, patientReportsActions } from 'redux/actions';
import { Container, PatientInfo, Readings } from '@mous221/hk-components';

import {
  MedicalRecord,
  PatientConcern,
  DoctorReport,
  RequestQuotation,
  ViewQuotations,
} from './';

const ViewRequestWrapper = styled.div`
  margin-top: 60px;
  min-height: 100vh;
  width: 100%;
  .ant-spin-nested-loading {
    width: 100%;
  }
  .request-content,
  .request-sidebar {
    padding: 0 15px;
  }
`;

class ViewRequest extends Component {
  state = {
    newPatient: false,
  };
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getRequest,
      getSharedAllergies,
      getSharedConditions,

      getSharedECGs,
      getSharedLabResults,
      getSharedMedicalReports,

      getSharedXRays,
      getReadings,
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    getRequest(id);

    getSharedAllergies(patientId);
    getSharedConditions(patientId);
    getSharedECGs(patientId);
    getSharedLabResults(patientId);
    getSharedMedicalReports(patientId);
    getSharedXRays(patientId);
    getReadings(patientId);
  }

  render() {
    const {
      request,
      sendQuotation,
      rejectRequest,
      xrays,
      radiologies,
      allergies,
      conditions,
      ecgs,
      labResults,
      medicalReports,
      readings,
      patientLoading,
      reportLoading,
    } = this.props;

    const isQuotationsSend =
      request &&
      request.hospitalReservationQuotation &&
      request.hospitalReservationQuotation.length > 0;

    return (
      <div>
        <Container>
          <ViewRequestWrapper>
            <Spin spinning={patientLoading}>
              {request && (
                <>
                  {isQuotationsSend ? (
                    <>
                      <ViewQuotations
                        request={request}
                        id={request.id}
                        readings={readings}
                      />
                    </>
                  ) : (
                    <RequestQuotation
                      request={request}
                      rejectRequest={rejectRequest}
                      sendQuotation={sendQuotation}
                      id={request.id}
                      readings={readings}
                    />
                  )}

                  <Row>
                    <Col span={16} className="request-content">
                      {request.patientConsultationRequest && (
                        <>
                          <PatientConcern request={request} />
                          <DoctorReport request={request} />
                        </>
                      )}

                      {allergies &&
                        conditions &&
                        ecgs &&
                        labResults &&
                        radiologies &&
                        request &&
                        medicalReports &&
                        xrays && (
                          <MedicalRecord
                            allergy={allergies.data}
                            condition={conditions.data}
                            ecg={ecgs.data}
                            labResult={labResults.data}
                            radiologies={radiologies}
                            patientId={request.patient.id}
                            medicalReport={medicalReports.data}
                            requestId={request.id}
                            loading={reportLoading}
                            xrays={xrays.data}
                          />
                        )}
                    </Col>
                    <Col span={8} className="request-sidebar">
                      <PatientInfo
                        patient={request.patient}
                        viewEligibility={true}
                        eligibility={request.eligibleToEnterUk}
                      />
                      {readings && (
                        <Readings readings={readings} requestId={request.id} />
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Spin>
          </ViewRequestWrapper>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { request, loading: patientLoading } = state.patient;
  const {
    xrays,
    radiologies,
    allergies,
    conditions,
    ecgs,
    labResults,
    medicalReports,
    readings,
    loading: reportLoading,
  } = state.patientReports;

  return {
    request,
    xrays,
    radiologies,
    allergies,
    conditions,
    ecgs,
    labResults,
    medicalReports,
    readings,
    patientLoading,
    reportLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRequest: (id) => dispatch(patientActions.getRequest(id)),
  rejectRequest: (id) => dispatch(patientActions.rejectRequest(id)),
  sendQuotation: (id, quotation) =>
    dispatch(patientActions.sendQuotation(id, quotation)),
  getSharedAllergies: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedAllergies(patientId, token)),
  getSharedConditions: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedConditions(patientId, token)),

  getSharedECGs: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedECGs(patientId, token)),
  getSharedLabResults: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedLabResults(patientId, token)),
  getSharedMedicalReports: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedMedicalReports(patientId, token)),
  getSharedRadiologies: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedRadiologies(patientId, token)),
  getSharedXRays: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedXRays(patientId, token)),
  getReadings: (patientId, token = null) =>
    dispatch(patientReportsActions.getReadings(patientId, token)),
});

const WrappedViewRequest = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewRequest);

export default WrappedViewRequest;

ViewRequest.propTypes = {
  match: PropTypes.object.isRequired,
  request: PropTypes.object,
  getRequest: PropTypes.func.isRequired,
  sendQuotation: PropTypes.func.isRequired,
  rejectRequest: PropTypes.func.isRequired,
  patientToken: PropTypes.string,
  xrays: PropTypes.object,
  mris: PropTypes.object,
  cts: PropTypes.object,
  allergies: PropTypes.object,
  conditions: PropTypes.object,
  ecgs: PropTypes.object,
  labResults: PropTypes.object,
  medicalReports: PropTypes.object,
  radiologies: PropTypes.object,
  readings: PropTypes.array,
  location: PropTypes.object.isRequired,
  getSharedAllergies: PropTypes.func.isRequired,
  getSharedConditions: PropTypes.func.isRequired,
  getSharedCTs: PropTypes.func.isRequired,
  getSharedECGs: PropTypes.func.isRequired,
  getSharedLabResults: PropTypes.func.isRequired,
  getSharedMedicalReports: PropTypes.func.isRequired,
  getSharedMRIs: PropTypes.func.isRequired,
  getSharedXRays: PropTypes.func.isRequired,
  getReadings: PropTypes.func.isRequired,
  getSharedRadiologies: PropTypes.func.isRequired,
  patientLoading: PropTypes.bool.isRequired,
  reportLoading: PropTypes.bool.isRequired,
};
