import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Breadcrumb, H1, Pagination, StateCard } from '@mous221/hk-utils';
import { patientReportsActions } from 'redux/actions';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';

const MedicalReportsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class MedicalReports extends Component {
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
      getSharedMedicalReports,
    } = this.props;

    if (patientId) {
      getSharedMedicalReports(patientId);
    }
  }

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedMedicalReports,
    } = this.props;

    if (patientId) {
      getSharedMedicalReports(patientId, null, page);
    }
  };

  render() {
    const {
      reports: medicalReports,
      match: {
        params: { requestId, patientId, invitationId },
        path,
      },
    } = this.props;

    const BreadcrumbLinks =
      requestId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
          ]
        : invitationId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}`,
            },
          ]
        : [];

    const url = (reportId) =>
      requestId && path.includes('/request')
        ? `/request/${requestId}/medical-report/${reportId}?patientId=${patientId}`
        : invitationId && path.includes('/invited-patient')
        ? `/invited-patient/${invitationId}/medical-report/${reportId}?patientId=${patientId}`
        : `#!`;
    return (
      <Container>
        <MedicalReportsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Medical Reports</H1>

          {medicalReports &&
            medicalReports.data?.map((report) => (
              <StateCard
                to={url(report.id)}
                key={report.createdAt}
                title={report.title}
                time={report.createdAt}
              />
            ))}
          {medicalReports &&
            medicalReports.meta?.total > medicalReports.meta?.per_page && (
              <Pagination
                pageSize={medicalReports.meta?.per_page}
                total={medicalReports.meta?.total}
                setPage={this.setPage}
              />
            )}
        </MedicalReportsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedMedicalReports: (patientId, sharedToken, page = null) =>
    dispatch(
      patientReportsActions.getSharedMedicalReports(
        patientId,
        sharedToken,
        page
      )
    ),
});

MedicalReports.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedMedicalReports: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalReports);
