import { alertActions } from 'redux/actions';
import { patientServices } from 'services';
import { history } from 'utils';
import patientConstants from './types';
import copy from 'copy-to-clipboard';

const getNewRequests = () => {
  return (dispatch) => {
    dispatch(getNewRequestsRequest());
    patientServices.getNewRequests().then((res) => {
      if (res.error) {
        dispatch(getNewRequestsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getNewRequestsSuccess(res.requests));
      }
    });
  };
};

const getNewRequestsRequest = () => ({
  type: patientConstants.GET_NEW_REQUESTS_REQUEST,
});

const getNewRequestsSuccess = (requests) => ({
  type: patientConstants.GET_NEW_REQUESTS_SUCCESS,
  requests,
});

const getNewRequestsFailure = (error) => ({
  type: patientConstants.GET_NEW_REQUESTS_FAILURE,
  error,
});

const getBookedRequests = () => {
  return (dispatch) => {
    dispatch(getBookedRequestsRequest());
    patientServices.getBookedRequests().then((res) => {
      if (res.error) {
        dispatch(getBookedRequestsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getBookedRequestsSuccess(res.requests));
      }
    });
  };
};

const getBookedRequestsRequest = () => ({
  type: patientConstants.GET_BOOKED_REQUESTS_REQUEST,
});

const getBookedRequestsSuccess = (requests) => ({
  type: patientConstants.GET_BOOKED_REQUESTS_SUCCESS,
  requests,
});

const getBookedRequestsFailure = (error) => ({
  type: patientConstants.GET_BOOKED_REQUESTS_FAILURE,
  error,
});

const getCanceledRequests = () => {
  return (dispatch) => {
    dispatch(getCanceledRequestsRequest());
    patientServices.getCanceledRequests().then((res) => {
      if (res.error) {
        dispatch(getCanceledRequestsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getCanceledRequestsSuccess(res.requests));
      }
    });
  };
};

const getCanceledRequestsRequest = () => ({
  type: patientConstants.GET_CANCELED_REQUESTS_REQUEST,
});

const getCanceledRequestsSuccess = (requests) => ({
  type: patientConstants.GET_CANCELED_REQUESTS_SUCCESS,
  requests,
});

const getCanceledRequestsFailure = (error) => ({
  type: patientConstants.GET_CANCELED_REQUESTS_FAILURE,
  error,
});

const getBookedAndCanceledRequests = () => {
  return (dispatch) => {
    dispatch(getBookedAndCanceledRequestsRequest());
    patientServices.getBookedAndCanceledRequests().then((res) => {
      if (res.error) {
        dispatch(getBookedAndCanceledRequestsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getBookedAndCanceledRequestsSuccess(res.requests));
      }
    });
  };
};

const getBookedAndCanceledRequestsRequest = () => ({
  type: patientConstants.GET_BOOKED_AND_CANCELED_REQUEST,
});

const getBookedAndCanceledRequestsSuccess = (requests) => ({
  type: patientConstants.GET_BOOKED_AND_CANCELED_SUCCESS,
  requests,
});

const getBookedAndCanceledRequestsFailure = (error) => ({
  type: patientConstants.GET_BOOKED_AND_CANCELED_FAILURE,
  error,
});

const getSendedQuotationRequests = () => {
  return (dispatch) => {
    dispatch(getSendedQuotationRequestsRequest());
    patientServices.getSendedQuotationRequests().then((res) => {
      if (res.error) {
        dispatch(getSendedQuotationRequestsFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getSendedQuotationRequestsSuccess(res.requests));
      }
    });
  };
};

const getSendedQuotationRequestsRequest = () => ({
  type: patientConstants.GET_SENDED_QUOTATION_REQUEST,
});

const getSendedQuotationRequestsSuccess = (requests) => ({
  type: patientConstants.GET_SENDED_QUOTATION_SUCCESS,
  requests,
});

const getSendedQuotationRequestsFailure = (error) => ({
  type: patientConstants.GET_SENDED_QUOTATION_FAILURE,
  error,
});
const getRequest = (id) => {
  return (dispatch) => {
    dispatch(getRequestRequest());
    patientServices.getRequest(id).then((res) => {
      if (res.error) {
        dispatch(getRequestFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getRequestSuccess(res.request));
      }
    });
  };
};

const getRequestRequest = () => ({
  type: patientConstants.GET_REQUEST_REQUEST,
});

const getRequestSuccess = (request) => ({
  type: patientConstants.GET_REQUEST_SUCCESS,
  request,
});

const getRequestFailure = (error) => ({
  type: patientConstants.GET_REQUEST_FAILURE,
  error,
});

const rejectRequest = (id) => {
  return (dispatch) => {
    dispatch(rejectRequestRequest());
    patientServices.rejectRequest(id).then((res) => {
      if (res.error) {
        dispatch(rejectRequestFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        if (res.message === 'Success') {
          dispatch(rejectRequestSuccess(id));
        }
      }
    });
  };
};

const rejectRequestRequest = () => ({
  type: patientConstants.REJECT_REQUEST_REQUEST,
});

const rejectRequestSuccess = (id) => ({
  type: patientConstants.REJECT_REQUEST_SUCCESS,
  id,
});

const rejectRequestFailure = (error) => ({
  type: patientConstants.REJECT_REQUEST_FAILURE,
  error,
});

const sendQuotation = (id, quotation) => {
  return (dispatch) => {
    dispatch(sendQuotationRequest());
    patientServices.sendQuotation(id, quotation).then((res) => {
      if (res.error) {
        dispatch(sendQuotationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(
          sendQuotationFile(
            res.quotation.id,
            quotation.quotationFile.fileList[0].originFileObj
          )
        );
      }
    });
  };
};

const sendQuotationFile = (id, quotation) => {
  return (dispatch) => {
    dispatch(sendQuotationRequest());
    patientServices.sendQuotationFile(id, quotation).then((res) => {
      if (res.error) {
        dispatch(sendQuotationFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(sendQuotationSuccess(res));
        dispatch(alertActions.success('Your Quotation has been sent'));
        history.push('/');
      }
    });
  };
};

const sendQuotationRequest = () => ({
  type: patientConstants.SEND_QUOTATION_REQUEST,
});

const sendQuotationSuccess = (quotation) => ({
  type: patientConstants.SEND_QUOTATION_SUCCESS,
  quotation,
});

const sendQuotationFailure = (error) => ({
  type: patientConstants.SEND_QUOTATION_FAILURE,
  error,
});

const generateShareToken = (patientId, sharedDate) => {
  return (dispatch) => {
    dispatch(generateShareTokenRequest());
    patientServices.generateShareToken(patientId, sharedDate).then((res) => {
      if (res.error) {
        dispatch(generateShareTokenFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        const baseURL =
          process.env.REACT_APP_PATIENT_URL ||
          `https://patient.staging.pat-rec.com`;
        const sharedLink = sharedDate
          ? `${baseURL}/${patientId}/shared-data?sharedToken=${
              res.token
            }&sharedDate=${JSON.stringify(sharedDate)}`
          : `${baseURL}/${patientId}/shared-data?sharedToken=${res.token}`;
        copy(sharedLink);
        dispatch(
          alertActions.success('Share link has been added to your Clipboard')
        );
        dispatch(generateShareTokenSuccess(res.token));
      }
    });
  };
};

const generateShareTokenRequest = () => ({
  type: patientConstants.GENERATE_SHARE_TOKEN_REQUEST,
});

const generateShareTokenSuccess = (token) => ({
  type: patientConstants.GENERATE_SHARE_TOKEN_SUCCESS,
  token,
});

const generateShareTokenFailure = (error) => ({
  type: patientConstants.GENERATE_SHARE_TOKEN_FAILURE,
  error,
});

// const copyToClipboard = (str) => {
//   console.log(str);
//   const el = document.createElement('textarea');
//   el.value = str;
//   document.body.appendChild(el);
//   el.select();
//   document.execCommand('copy');
//   document.body.removeChild(el);
// };
const clearData = () => ({
  type: patientConstants.CLEAR_DATA,
});

const userActions = {
  getNewRequests,
  getBookedRequests,
  getCanceledRequests,
  getRequest,
  rejectRequest,
  sendQuotation,
  generateShareToken,
  getBookedAndCanceledRequests,
  getSendedQuotationRequests,
  clearData,
};

export default userActions;
