import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as scenes from 'scenes';
import { alertActions } from 'redux/actions';

import { GlobalStyles, history } from 'utils';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';

import { PrivateRoute } from './PrivateRoute';
import 'utils/fetchIntercept';

class App extends Component {
  constructor(props) {
    super(props);

    const { clearAlerts } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      clearAlerts();
    });
  }
  state = {};
  static getDerivedStateFromProps({ alert }, state) {
    switch (alert.type) {
      case 'success':
        NotificationManager.success(alert.message);
        break;
      case 'warning':
        NotificationManager.warning(alert.message);
        break;
      case 'error':
        NotificationManager.error(alert.message);
        break;
      case 'info':
        NotificationManager.info(alert.message);
        break;
      default:
        break;
    }
    return {};
  }

  render() {
    return (
      <div>
        <GlobalStyles />
        <Router history={history}>
          <div>
            <PrivateRoute exact path="/" component={scenes.HomePage} />
            <PrivateRoute
              exact
              path="/request/:id"
              component={scenes.ViewRequestPage}
            />
            <PrivateRoute
              exact
              path="/profile"
              component={scenes.ProfilePage}
            />
            <PrivateRoute
              exact
              path="/hospital-requests"
              component={scenes.HospitalRequestsPage}
            />
            <PrivateRoute
              exact
              path="/invite-patient"
              component={scenes.InvitePatientPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId"
              component={scenes.InvitedPatientPage}
            />
            <PrivateRoute
              exact
              path="/sent-quotations"
              component={scenes.SendQuotationPage}
            />
            <PrivateRoute
              exact
              path="/booked-requests"
              component={scenes.BookedRequestsPage}
            />

            <PrivateRoute
              exact
              path="/request/:requestId/radiology/mri/:id"
              component={scenes.MRIPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/mri/:patientId"
              component={scenes.MRIsPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/ct/:patientId"
              component={scenes.CTsPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/xrays/:patientId"
              component={scenes.XRayPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/xray/:id"
              component={scenes.XRaySinglePage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/radiologist/:patientId"
              component={scenes.PatientRadiologistPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/radiologies/:patientId"
              component={scenes.PatientRadiologiesPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/ecgs/:patientId"
              component={scenes.PatientECGsPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/ecg/:id"
              component={scenes.PatientECGPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/lab-results/:patientId"
              component={scenes.PatientLabResultsPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/lab-result/:id"
              component={scenes.PatientLabResultPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/medical-reports/:patientId"
              component={scenes.PatientMedicalReportsPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/medical-report/:id"
              component={scenes.PatientMedicalReportPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/allergy/:id"
              component={scenes.PatientAllergyPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/allergies/:patientId"
              component={scenes.PatientAllergiesPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/condition/:id"
              component={scenes.PatientConditionPage}
            />
            <PrivateRoute
              exact
              path="/request/:requestId/conditions/:patientId"
              component={scenes.PatientConditionsPage}
            />

            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/radiology/mri/:id"
              component={scenes.MRIPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/mri/:patientId"
              component={scenes.MRIsPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/ct/:patientId"
              component={scenes.CTsPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/xrays/:patientId"
              component={scenes.XRayPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/xray/:id"
              component={scenes.XRaySinglePage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/radiologist/:patientId"
              component={scenes.PatientRadiologistPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/radiologies/:patientId"
              component={scenes.PatientRadiologiesPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/ecgs/:patientId"
              component={scenes.PatientECGsPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/ecg/:id"
              component={scenes.PatientECGPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/lab-results/:patientId"
              component={scenes.PatientLabResultsPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/lab-result/:id"
              component={scenes.PatientLabResultPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/medical-reports/:patientId"
              component={scenes.PatientMedicalReportsPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/medical-report/:id"
              component={scenes.PatientMedicalReportPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/allergy/:id"
              component={scenes.PatientAllergyPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/allergies/:patientId"
              component={scenes.PatientAllergiesPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/condition/:id"
              component={scenes.PatientConditionPage}
            />
            <PrivateRoute
              exact
              path="/invited-patient/:invitationId/conditions/:patientId"
              component={scenes.PatientConditionsPage}
            />

            <PrivateRoute
              exact
              path="/reports"
              component={scenes.ReportsPage}
            />
            <Route path="/login" component={scenes.LoginPage} />
          </div>
        </Router>
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alert } = state;
  return {
    alert,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearAlerts: () => dispatch(alertActions.clear()),
});

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export default connectedApp;

App.propTypes = {
  clearAlerts: PropTypes.func,
  alert: PropTypes.object,
};
