import { invitePatientConstants } from 'redux/actions/types';

const initialState = {
  loading: false,
  invitation: {},
  invitedPatient: {
    data: [],
  },
  consultationTypes: [],
};

function invitePatient(state = initialState, action) {
  switch (action.type) {
    case invitePatientConstants.GET_INVITED_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invitePatientConstants.GET_INVITED_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invitedPatient: action.requests,
      };
    case invitePatientConstants.GET_INVITED_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case invitePatientConstants.SHOW_INVITED_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invitePatientConstants.SHOW_INVITED_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invitation: action.request,
      };
    case invitePatientConstants.SHOW_INVITED_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case invitePatientConstants.INVITE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invitePatientConstants.INVITE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        invitation: action.request,
      };
    case invitePatientConstants.INVITE_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        errors: { errors: action.error },
      };
    case invitePatientConstants.GET_CONSULTATION_TYPE_REQUEST:
      return {
        ...state,
        consultationTypesRequest: true,
        loading: true,
      };
    case invitePatientConstants.GET_CONSULTATION_TYPE_SUCCESS:
      return {
        ...state,
        consultationTypes: action.consultationTypes,
        loading: false,
      };
    case invitePatientConstants.GET_CONSULTATION_TYPE_FAILURE:
      return {
        ...state,
        errors: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export default invitePatient;
