import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'utils';
import PropTypes from 'prop-types';
import {
  BaseLayout,
  LoginForm,
  FullPage,
  Container,
} from '@mous221/hk-components';
import { userActions, alertActions } from 'redux/actions';
import { Spin } from 'antd';

class Login extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
  };
  componentWillMount() {
    localStorage.setItem('userType', 'hospital');
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      history.push('/');
    }
  }
  render() {
    const { loading } = this.props;

    return (
      <BaseLayout>
        <FullPage>
          <Container>
            <Spin spinning={loading}>
              <LoginForm {...this.props} registerLink="" noRegister={true} />
            </Spin>
          </Container>
        </FullPage>
      </BaseLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading } = state.authentication;
  return {
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  errorAlert: (err) => dispatch(alertActions.error(err)),
  login: (email, password) => dispatch(userActions.login(email, password)),
  resetPassword: (email) => dispatch(userActions.resetPassword(email)),
});

const connectedLoginPage = connect(mapStateToProps, mapDispatchToProps)(Login);
export default connectedLoginPage;
