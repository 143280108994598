import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { patientReportsActions } from 'redux/actions';
import { Breadcrumb, H1, Pagination, StateCard } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';

const LabResultsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class LabResults extends Component {
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
      getSharedLabResults,
    } = this.props;

    if (patientId) {
      getSharedLabResults(patientId);
    }
  }

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedLabResults,
    } = this.props;

    if (patientId) {
      getSharedLabResults(patientId, null, page);
    }
  };

  render() {
    const {
      reports: labResults,
      match: {
        params: { requestId, patientId, invitationId },
        path,
      },
    } = this.props;

    const BreadcrumbLinks =
      requestId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
          ]
        : invitationId
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
          ]
        : [];

    const url = (reportId) =>
      requestId && path.includes('/request')
        ? `/request/${requestId}/lab-result/${reportId}?patientId=${patientId}`
        : invitationId && path.includes('/invited-patient')
        ? `/invited-patient/${invitationId}/lab-result/${reportId}?patientId=${patientId}`
        : `#!`;
    return (
      <Container>
        <LabResultsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Lab Results</H1>
          {labResults &&
            labResults.data?.map((report) => (
              <>
                {console.log('report', report)}
                <StateCard
                  to={url(report.id)}
                  title={report.title}
                  time={report.createdAt}
                  key={report.id}
                />
              </>
            ))}
          {labResults && labResults.meta?.total > labResults.meta?.per_page && (
            <Pagination
              pageSize={labResults.meta?.per_page}
              total={labResults.meta?.total}
              setPage={this.setPage}
            />
          )}
        </LabResultsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedLabResults: (patientId, sharedToken, page = null) =>
    dispatch(
      patientReportsActions.getSharedLabResults(patientId, sharedToken, page)
    ),
});

LabResults.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedLabResults: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabResults);
