const patientReportsConstants = {
  GET_XRAY_REQUEST: 'GET_XRAY_REQUEST',
  GET_XRAY_SUCCESS: 'GET_XRAY_SUCCESS',
  GET_XRAY_FAILURE: 'GET_XRAY_FAILURE',
  GET_SHARED_XRAYS_REQUEST: 'GET_SHARED_XRAYS_REQUEST',
  GET_SHARED_XRAYS_SUCCESS: 'GET_SHARED_XRAYS_SUCCESS',
  GET_SHARED_XRAYS_FAILURE: 'GET_SHARED_XRAYS_FAILURE',

  GET_MRI_REQUEST: 'GET_MRI_REQUEST',
  GET_MRI_SUCCESS: 'GET_MRI_SUCCESS',
  GET_MRI_FAILURE: 'GET_MRI_FAILURE',
  GET_SHARED_MRIS_REQUEST: 'GET_SHARED_MRIS_REQUEST',
  GET_SHARED_MRIS_SUCCESS: 'GET_SHARED_MRIS_SUCCESS',
  GET_SHARED_MRIS_FAILURE: 'GET_SHARED_MRIS_FAILURE',

  GET_CT_REQUEST: 'GET_CT_REQUEST',
  GET_CT_SUCCESS: 'GET_CT_SUCCESS',
  GET_CT_FAILURE: 'GET_CT_FAILURE',
  GET_SHARED_CTS_REQUEST: 'GET_SHARED_CTS_REQUEST',
  GET_SHARED_CTS_SUCCESS: 'GET_SHARED_CTS_SUCCESS',
  GET_SHARED_CTS_FAILURE: 'GET_SHARED_CTS_FAILURE',

  GET_ALLERGY_REQUEST: 'GET_ALLERGY_REQUEST',
  GET_ALLERGY_SUCCESS: 'GET_ALLERGY_SUCCESS',
  GET_ALLERGY_FAILURE: 'GET_ALLERGY_FAILURE',
  GET_SHARED_ALLERGIES_REQUEST: 'GET_SHARED_ALLERGIES_REQUEST',
  GET_SHARED_ALLERGIES_SUCCESS: 'GET_SHARED_ALLERGIES_SUCCESS',
  GET_SHARED_ALLERGIES_FAILURE: 'GET_SHARED_ALLERGIES_FAILURE',

  GET_CONDITION_REQUEST: 'GET_CONDITION_REQUEST',
  GET_CONDITION_SUCCESS: 'GET_CONDITION_SUCCESS',
  GET_CONDITION_FAILURE: 'GET_CONDITION_FAILURE',
  GET_SHARED_CONDITIONS_REQUEST: 'GET_SHARED_CONDITIONS_REQUEST',
  GET_SHARED_CONDITIONS_SUCCESS: 'GET_SHARED_CONDITIONS_SUCCESS',
  GET_SHARED_CONDITIONS_FAILURE: 'GET_SHARED_CONDITIONS_FAILURE',

  GET_ECG_REQUEST: 'GET_ECG_REQUEST',
  GET_ECG_SUCCESS: 'GET_ECG_SUCCESS',
  GET_ECG_FAILURE: 'GET_ECG_FAILURE',
  GET_SHARED_ECGS_REQUEST: 'GET_SHARED_ECGS_REQUEST',
  GET_SHARED_ECGS_SUCCESS: 'GET_SHARED_ECGS_SUCCESS',
  GET_SHARED_ECGS_FAILURE: 'GET_SHARED_ECGS_FAILURE',

  GET_LAB_RESULT_REQUEST: 'GET_LAB_RESULT_REQUEST',
  GET_LAB_RESULT_SUCCESS: 'GET_LAB_RESULT_SUCCESS',
  GET_LAB_RESULT_FAILURE: 'GET_LAB_RESULT_FAILURE',
  GET_SHARED_LAB_RESULTS_REQUEST: 'GET_SHARED_LAB_RESULTS_REQUEST',
  GET_SHARED_LAB_RESULTS_SUCCESS: 'GET_SHARED_LAB_RESULTS_SUCCESS',
  GET_SHARED_LAB_RESULTS_FAILURE: 'GET_SHARED_LAB_RESULTS_FAILURE',

  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',
  GET_SHARED_REPORTS_REQUEST: 'GET_SHARED_REPORTS_REQUEST',
  GET_SHARED_REPORTS_SUCCESS: 'GET_SHARED_REPORTS_SUCCESS',
  GET_SHARED_REPORTS_FAILURE: 'GET_SHARED_REPORTS_FAILURE',

  GET_RADIOLOGY_REQUEST: 'GET_RADIOLOGY_REQUEST',
  GET_RADIOLOGY_SUCCESS: 'GET_RADIOLOGY_SUCCESS',
  GET_RADIOLOGY_FAILURE: 'GET_RADIOLOGY_FAILURE',
  GET_SHARED_RADIOLOGIES_REQUEST: 'GET_SHARED_RADIOLOGIES_REQUEST',
  GET_SHARED_RADIOLOGIES_SUCCESS: 'GET_SHARED_RADIOLOGIES_SUCCESS',
  GET_SHARED_RADIOLOGIES_FAILURE: 'GET_SHARED_RADIOLOGIES_FAILURE',

  GET_PATIENT_READING_REQUEST: 'GET_PATIENT_READING_REQUEST',
  GET_PATIENT_READING_SUCCESS: 'GET_PATIENT_READING_SUCCESS',
  GET_PATIENT_READING_FAILURE: 'GET_PATIENT_READING_FAILURE',

  CLEAR_DATA: 'CLEAR_DATA',
};

export default patientReportsConstants;
