import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invitePatientActions } from 'redux/actions';
import { Spin } from 'antd';
import styled from '@emotion/styled';
import { H1, H2, Modal, PrimaryButton } from '@mous221/hk-utils';
import { Form, Input, Col, Row } from 'antd';
import { Container } from '@mous221/hk-components';
import { CheckIcon } from 'images';
import { Lab } from 'images';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import RequestMedicalRecordForm from './RequestMedicalRecordForm';
import { HospitalIcon } from 'images';

const FormItem = Form.Item;
const CancelButton = styled.div`
  a {
    background-color: #fff;
    color: #97d7d8;
    border-color: #97d7d8;
  }
`;
const FormWrapper = styled.div`
  text-align: left;
  label {
    font-size: 14px;
    font-weight: bold;
  }
  .ant-form-item {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-select-selection,
  input,
  .ant-input.text-area {
    height: 50px;
    width: 95%;
    display: block;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #bfbfbf;
    font-family: Heebo;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    &:focus {
      border: solid 2px #000;
    }
  }
  .ant-input.text-area {
    width: 97.5%;
    min-height: 200px;
    resize: none;
  }
  .has-error {
    .ant-input,
    .ant-select-selection {
      border: 1px solid red;
    }
  }
`;
const RequestScanButton = styled.button`
  width: 200px;
  border-radius: 4px;
  background-color: #f9f9fb;
  border: solid 1px #f9f9fb;
  margin: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 10px;
  }
  &:hover {
    border: solid 1px #97d7d8;
    color: #97d7d8;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 40px;
  h2 {
    font-size: 35px;
    margin-bottom: 0px;
    & + p {
      font-size: 15px;
      margin-bottom: 40px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  a,
  button {
    height: 70px;
    font-size: 18px;
    font-weight: normal;
    border-radius: 6px;
    text-align: center;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
class InvitePatient extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    invitePatient: PropTypes.func.isRequired,
    consultationTypes: PropTypes.array.isRequired,
    getConsultationType: PropTypes.func.isRequired,
  };

  state = {
    showModal: false,
    requests: [],
    scanType: null,
    scan: null,
  };

  handelCloseModal = () => {
    this.setState({ showModal: false, scanType: null, scan: null });
  };

  handelShowModal = (scanType, scan = null) => {
    console.log('scan', scan);
    this.setState({ showModal: true, scanType, scan });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { invitePatient } = this.props;
    this.props.form.validateFieldsAndScroll((err, values) => {
      const { requests } = this.state;
      const submittedRequests = requests.map((request) => ({
        ...request,
        scan_type: request.scanType,
      }));
      if (!err) {
        invitePatient({
          ...values,
          requests: submittedRequests,
        });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      loading,
    } = this.props;
    const { showModal, scanType, requests, scan } = this.state;
    const conditionRequests = requests.filter(
      (request) => request.scan_type === 'condition'
    );
    const MedicalRequests = requests.filter(
      (request) => request.scan_type === 'medical_report'
    );
    const readingRequests = requests.filter((request) =>
      request.scan_type?.includes('reading')
    );
    const ecgRequests = requests.filter(
      (request) => request.scan_type === 'ecg'
    );
    const allergyRequests = requests.filter(
      (request) => request.scan_type === 'allergy'
    );
    const ctRequests = requests.filter(
      (request) => request.scan_type === 'radiology-ct'
    );
    const xrayRequests = requests.filter(
      (request) => request.scan_type === 'radiology-xray'
    );
    const labResultRequests = requests.filter(
      (request) => request.scan_type === 'lab_result'
    );

    return (
      <>
        <Container>
          <H1>Add Hospital Requests</H1>
          <Form onSubmit={this.handleSubmit}>
            <Wrapper>
              <Spin spinning={loading}>
                <div>
                  <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <HospitalIcon />
                  </div>
                  <H2>Add Hospital Requests</H2>
                  <p>Please add info to Add Hospital Requests</p>
                </div>

                <FormWrapper>
                  <Row>
                    <Col span={12}>
                      <FormItem label="Name">
                        {getFieldDecorator('name', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter Name',
                            },
                          ],
                        })(<Input type="text" />)}
                      </FormItem>
                    </Col>
                    <Col span={12}>
                      <FormItem label="Email">
                        {getFieldDecorator('email', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter Email',
                            },
                          ],
                        })(<Input type="email" />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <FormItem label="Invitation Text.">
                      {getFieldDecorator('invitationText', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter invitation text',
                          },
                        ],
                      })(
                        <Input.TextArea
                          placeholder="Invitation Text"
                          className="text-area"
                        />
                      )}
                    </FormItem>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          conditionRequests.length <= 0
                            ? this.handelShowModal('condition')
                            : this.handelShowModal(
                                'condition',
                                conditionRequests[0]
                              )
                        }
                        type="button"
                      >
                        {conditionRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        Conditions
                      </RequestScanButton>
                    </Col>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          MedicalRequests.length <= 0
                            ? this.handelShowModal('medical_report')
                            : this.handelShowModal(
                                'medical_report',
                                MedicalRequests[0]
                              )
                        }
                        type="button"
                      >
                        {MedicalRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        Medical Report
                      </RequestScanButton>
                    </Col>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          readingRequests.length <= 0
                            ? this.handelShowModal('reading')
                            : this.handelShowModal('reading', {
                                ...readingRequests[0],
                                scanType: [
                                  ...readingRequests.map((red) => red.scanType),
                                ],
                              })
                        }
                        type="button"
                      >
                        {readingRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        Readings
                      </RequestScanButton>
                    </Col>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          ecgRequests.length <= 0
                            ? this.handelShowModal('ecg')
                            : this.handelShowModal('ecg', ecgRequests[0])
                        }
                        type="button"
                      >
                        {ecgRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        ECG
                      </RequestScanButton>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          allergyRequests.length <= 0
                            ? this.handelShowModal('allergy')
                            : this.handelShowModal(
                                'allergy',
                                allergyRequests[0]
                              )
                        }
                        type="button"
                      >
                        {allergyRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        Allergies
                      </RequestScanButton>
                    </Col>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          ctRequests.length <= 0
                            ? this.handelShowModal('radiology-ct')
                            : this.handelShowModal(
                                'radiology-ct',
                                ctRequests[0]
                              )
                        }
                        type="button"
                      >
                        {ctRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        MRI/CT Scan
                      </RequestScanButton>
                    </Col>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          labResultRequests.length <= 0
                            ? this.handelShowModal('lab_result')
                            : this.handelShowModal(
                                'lab_result',
                                labResultRequests[0]
                              )
                        }
                        type="button"
                      >
                        {labResultRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        Lab Result
                      </RequestScanButton>
                    </Col>
                    <Col span={6}>
                      <RequestScanButton
                        onClick={() =>
                          xrayRequests.length <= 0
                            ? this.handelShowModal('radiology-xray')
                            : this.handelShowModal(
                                'radiology-xray',
                                xrayRequests[0]
                              )
                        }
                        type="button"
                      >
                        {xrayRequests.length > 0 && (
                          <CheckIcon width={20} height={20} />
                        )}
                        X Ray
                      </RequestScanButton>
                    </Col>
                  </Row>
                </FormWrapper>
              </Spin>
            </Wrapper>
            <ButtonWrapper>
              <CancelButton>
                <PrimaryButton>
                  <Link to="/hospital-requests">Cancel</Link>
                </PrimaryButton>
              </CancelButton>

              <PrimaryButton>
                <button htmltype="submit" className="submit">
                  Submit
                </button>
              </PrimaryButton>
            </ButtonWrapper>
          </Form>
        </Container>
        <Modal
          title={`Request ${scanType
            ?.replaceAll('_', ' ')
            .replaceAll('-', ' ')}`}
          image={Lab}
          showModal={showModal}
          handelCloseModal={this.handelCloseModal}
        >
          <>
            <RequestMedicalRecordForm
              requestScan={(scan) => {
                const isReading = Array.isArray(scan.scanType);
                let readingRequests;
                if (isReading) {
                  // readingRequests = scan.scanType.map((sc) => ({
                  //   ...scan,
                  //   scanType: sc,
                  // }));
                  readingRequests = [{ ...scan, scanType: 'reading' }];
                }

                const thereIsRequest = requests.find(
                  (request) => request.scanType === scan.scanType
                );
                const isEmpty = Boolean(!scan.scanName && !scan.notes);
                const removedRequest = requests.filter(
                  (request) => request.scanType !== scan.scanType
                );
                const newRequests = (requests) =>
                  isReading
                    ? [
                        ...requests.filter(
                          (request) => !request.scanType.startsWith('reading')
                        ),
                        ...readingRequests,
                      ]
                    : isEmpty
                    ? removedRequest
                    : thereIsRequest
                    ? requests.map((request) =>
                        request.scanType === scan.scanType ? scan : request
                      )
                    : [...requests, scan];

                return this.setState((state) => ({
                  ...state,
                  requests: newRequests(state.requests),
                }));
              }}
              scanType={scanType}
              handelCloseModal={this.handelCloseModal}
              scan={scan}
            />
          </>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, consultationTypes } = state.invitePatient;
  return {
    loading,
    consultationTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  invitePatient: (patient) =>
    dispatch(invitePatientActions.invitePatient(patient)),
  getConsultationType: () =>
    dispatch(invitePatientActions.getConsultationType()),
});

export default Form.create({ name: 'invite_patient' })(
  connect(mapStateToProps, mapDispatchToProps)(InvitePatient)
);
