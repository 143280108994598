import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invitePatientActions } from 'redux/actions';
import { Container, PatientInfo } from '@mous221/hk-components';
import { H2 } from '@mous221/hk-utils';
import { Row, Col, Spin } from 'antd';
import ViewQuotations from './ViewQuotations';
import styled from '@emotion/styled';
import Readings from './Readings';
import MedicalRecords from './MedicalRecords';
const DoctorRequest = styled.div`
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  h3 {
    font-family: 'AvenirNext', Arial, sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
    & + p {
      font-family: 'AvenirNext', Arial, sans-serif;
      font-size: 14.4px;
      font-weight: normal;
      color: #9298ac;
    }
  }
  p {
    font-family: 'AvenirNext', Arial, sans-serif;
    font-size: 16px;
    color: #060c2b;
    line-height: 2;
  }
`;
const RequestsWrapper = styled.div`
  margin-bottom: 60px;
  max-width: 95%;
  h2 {
    font-family: 'AvenirNext', Arial, sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
  }
`;

export class invitedPatient extends Component {
  static propTypes = {
    showInvitedPatient: PropTypes.func.isRequired,
    invitation: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
  };
  componentDidMount() {
    const {
      showInvitedPatient,
      match: {
        params: { invitationId },
      },
    } = this.props;
    showInvitedPatient(invitationId);
  }

  render() {
    const {
      invitation,
      loading,
      match: {
        params: { invitationId },
      },
    } = this.props;

    return (
      <Container>
        <ViewQuotations request={invitation} />
        <Row>
          <Spin spinning={loading}>
            <Col span={16} className="request-content">
              <RequestsWrapper>
                {invitation.requests?.length > 0 && (
                  <>
                    {invitation.patient && (
                      <MedicalRecords
                        patientId={invitation?.patient?.id}
                        invitationId={invitationId}
                      />
                    )}
                    <H2>Hospital Requests</H2>
                    {invitation.requests?.map((request, index) => {
                      const note = request.notes || 'No notes';
                      const notes = note.split(/\n/g);
                      return (
                        <DoctorRequest key={`doctor-request-${index}`}>
                          <h3>{request.title}</h3>
                          <p>
                            Scan Type:{' '}
                            {request.scan_type
                              ?.replaceAll('-', ' ')
                              .replaceAll('_', ' ')}
                          </p>

                          <div>
                            {notes &&
                              notes.length > 0 &&
                              notes.map((note, index) => (
                                <p key={`${note}-${index}`}>{note}</p>
                              ))}
                          </div>
                        </DoctorRequest>
                      );
                    })}
                  </>
                )}
                {invitation.requests?.length === 0 && (
                  <H2>There are no requests</H2>
                )}
              </RequestsWrapper>
            </Col>
            <Col span={8} className="request-sidebar">
              {invitation.patient && (
                <div
                  style={{
                    marginTop:
                      invitation.requests?.length === 0 ? '20px' : '70px',
                  }}
                >
                  <PatientInfo
                    patient={invitation.patient}
                    viewEligibility={true}
                    eligibility={invitation.patient?.eligibleToEnterUk}
                  />
                  <Readings patientId={invitation?.patient?.id} />
                </div>
              )}
            </Col>
          </Spin>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, invitation } = state.invitePatient;
  return {
    loading,
    invitation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showInvitedPatient: (invitationId) =>
    dispatch(invitePatientActions.showInvitedPatient(invitationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(invitedPatient);
