import React from 'react';
import styled from '@emotion/styled/macro';
import { Message, Message2X } from 'images';
import { H1 } from '@mous221/hk-utils';
import NewRequest from './NewRequest';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

const NewRequestWrapper = styled.div`
  margin-bottom: 30px;
  & > h1 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  margin-bottom: 15px;
`;
const ImgWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 20px;
  img {
    width: 49px;
    height: 49px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 420px;
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #97d7d8;
    margin: 0;
  }
  p {
    margin: 0;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #060c2b;
  }
`;

export default function NewRequests(props) {
  const { rejectRequest, requests, loading } = props;

  return (
    <NewRequestWrapper>
      <H1>New Requests</H1>
      <Spin spinning={loading}>
        {(!requests || (requests && requests.data.length < 1)) && (
          <HeaderWrapper>
            <ImgWrapper>
              <picture>
                <source srcSet={`${Message2X} 2x`} />
                <img src={Message} alt="Message" />
              </picture>
            </ImgWrapper>
            <ContentWrapper>
              <h2>There are no new requests</h2>
              <p>
                Please check back later. When a patient makes a request, you
                will receive a notification.
              </p>
            </ContentWrapper>
          </HeaderWrapper>
        )}
        {requests &&
          requests.data.map((request) => (
            <NewRequest
              key={request.id}
              handelShowModal={() => {}}
              request={request}
              rejectRequest={rejectRequest}
              setCancelRequestsid={() => {}}
            />
          ))}
      </Spin>
    </NewRequestWrapper>
  );
}

NewRequests.propTypes = {
  requests: PropTypes.object.isRequired,
  rejectRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
