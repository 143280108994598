import React, { Component } from 'react';
import { connect } from 'react-redux';
import { patientReportsActions } from 'redux/actions';
import PropTypes from 'prop-types';
import MedicalRecord from './MedicalRecord';

export class MedicalRecords extends Component {
  static propTypes = {
    xrays: PropTypes.object,
    mris: PropTypes.object,
    cts: PropTypes.object,
    allergies: PropTypes.object,
    conditions: PropTypes.object,
    ecgs: PropTypes.object,
    labResults: PropTypes.object,
    medicalReports: PropTypes.object,
    radiologies: PropTypes.object,
    readings: PropTypes.array,
    location: PropTypes.object,
    getSharedAllergies: PropTypes.func.isRequired,
    getSharedConditions: PropTypes.func.isRequired,
    getSharedECGs: PropTypes.func.isRequired,
    getSharedLabResults: PropTypes.func.isRequired,
    getSharedMedicalReports: PropTypes.func.isRequired,
    getSharedRadiologies: PropTypes.func.isRequired,
    getSharedXRays: PropTypes.func.isRequired,
    patientLoading: PropTypes.bool.isRequired,
    reportLoading: PropTypes.bool.isRequired,
    patientId: PropTypes.number.isRequired,
    invitationId: PropTypes.string.isRequired,
  };
  componentDidMount() {
    const {
      patientId,
      getSharedAllergies,
      getSharedConditions,
      getSharedECGs,
      getSharedLabResults,
      getSharedMedicalReports,
      getSharedXRays,
      getSharedRadiologies,
    } = this.props;

    getSharedAllergies(patientId);

    getSharedConditions(patientId);

    getSharedECGs(patientId);

    getSharedLabResults(patientId);

    getSharedMedicalReports(patientId);

    getSharedXRays(patientId);

    getSharedRadiologies(patientId);
  }
  render() {
    const {
      xrays,
      allergies,
      conditions,
      ecgs,
      labResults,
      medicalReports,
      reportLoading,
      patientId,
      invitationId,
      radiologies,
    } = this.props;

    return (
      <div>
        <MedicalRecord
          allergy={allergies?.data}
          condition={conditions?.data}
          ecg={ecgs?.data}
          labResult={labResults?.data}
          radiologies={radiologies?.data}
          patientId={patientId}
          medicalReport={medicalReports?.data}
          xrays={xrays?.data}
          invitationId={invitationId}
          loading={reportLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { request, loading: patientLoading } = state.patient;
  const {
    xrays,
    allergies,
    conditions,
    ecgs,
    labResults,
    medicalReports,
    radiologies,
    loading: reportLoading,
  } = state.patientReports;

  return {
    request,
    xrays,
    allergies,
    conditions,
    ecgs,
    labResults,
    medicalReports,
    patientLoading,
    reportLoading,
    radiologies,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedAllergies: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedAllergies(patientId, token)),
  getSharedConditions: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedConditions(patientId, token)),
  getSharedECGs: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedECGs(patientId, token)),
  getSharedLabResults: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedLabResults(patientId, token)),
  getSharedMedicalReports: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedMedicalReports(patientId, token)),
  getSharedXRays: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedXRays(patientId, token)),
  getSharedRadiologies: (patientId, token = null) =>
    dispatch(patientReportsActions.getSharedRadiologies(patientId, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecords);
