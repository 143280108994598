const getNewRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/hospital/patient-quotation-requests/new`, requestOptions)
    .then((requests) => {
      return { error: false, requests: requests };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getSendedQuotationRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/hospital/patient-quotation-requests?status=admin-contacted,canceled,new`,
    requestOptions
  )
    .then((requests) => {
      return { error: false, requests: requests };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getBookedRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/hospital/patient-quotation-requests?status=booked,canceled`,
    requestOptions
  )
    .then((requests) => {
      return { error: false, requests: requests };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getBookedAndCanceledRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/hospital/patient-quotation-requests`, requestOptions)
    .then((requests) => {
      return { error: false, requests: requests };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getCanceledRequests = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/hospital/patient-quotation-requests?status=canceled`,
    requestOptions
  )
    .then((requests) => {
      return { error: false, requests: requests };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};
const getRequest = (requestId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(
    `/hospital/patient-quotation-requests/${requestId}`,
    requestOptions
  )
    .then((request) => {
      return { error: false, request: request };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const rejectRequest = (id) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };

  return fetch(
    `/hospital/patient-quotation-requests/${id}/reject`,
    requestOptions
  )
    .then((res) => {
      return { error: false, message: res.message };
    })
    .catch((error) => {
      return { error: true, errors: error.message };
    });
};

const sendQuotation = (id, quotation) => {
  const quotObj = {
    durationInDays: quotation.durationInDays,
    earliestCheckInTime: quotation.earliestCheckInTime,
    price: quotation.price,
    // note: quotation.note ? quotation.note : null,
  };

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(quotObj),
  };

  return fetch(
    `/hospital/patient-quotation-requests/${id}/accept`,
    requestOptions
  )
    .then((res) => {
      return { error: false, quotation: res };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const sendQuotationFile = (id, fileInput) => {
  const formdata = new FormData();
  formdata.append('file', fileInput, fileInput.name);

  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: formdata,
  };

  return fetch(
    `/hospital/patients-quotations/${id}/quotation-file`,
    requestOptions
  )
    .then((res) => {
      return { error: false, res: res };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const generateShareToken = (patientId) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
  };

  return fetch(
    `/hospital/patient/${patientId}/generate-information-share-token`,
    requestOptions
  )
    .then((res) => {
      return { error: false, token: res.informationShareToken };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const patientServices = {
  getNewRequests,
  getBookedRequests,
  getCanceledRequests,
  getRequest,
  rejectRequest,
  sendQuotation,
  generateShareToken,
  sendQuotationFile,
  getBookedAndCanceledRequests,
  getSendedQuotationRequests,
};

export default patientServices;
