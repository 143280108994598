import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import { SendedQuotations } from './components';
const SendQuotationsPage = (props) => (
  <MainLayout
    footer="main"
    SentQuotations={true}
    children={
      <>
        <SendedQuotations {...props} />
      </>
    }
    {...props}
  />
);
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(SendQuotationsPage);
