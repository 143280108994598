export const getInvitedPatient = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/hospital/invites/patient`, requestOptions)
    .then((requests) => {
      return { error: false, requests };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

export const showInvitedPatient = (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/hospital/invites/patient/${id}`, requestOptions)
    .then((request) => {
      return { error: false, request };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

export const invitePatient = (invitedPatient) => {
  const requestOptions = {
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(invitedPatient),
  };

  return fetch(`/hospital/invites/patient`, requestOptions)
    .then((request) => {
      return { error: false, request };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const getConsultationType = () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  return fetch(`/consultation-type`, requestOptions)
    .then((consultationTypes) => {
      return { error: false, consultationTypes };
    })
    .catch((error) => {
      return { error: true, errors: error };
    });
};

const invitePatientServices = {
  getInvitedPatient,
  showInvitedPatient,
  invitePatient,
  getConsultationType,
};

export default invitePatientServices;
