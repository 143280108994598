import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Upload, Icon, Form, Input, DatePicker } from 'antd';
import { connect } from 'react-redux';
import { patientActions } from 'redux/actions';
import {
  H2,
  ProfilePic,
  PrimaryButton,
  convertDateToFullStringDate,
} from '@mous221/hk-utils';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { CloseIcon, CheckIcon } from 'images';

const FormItem = Form.Item;

function disabledDate(current) {
  return current && current < moment().endOf('day');
}

const RequestQuotationWrapper = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  margin: 0 15px;
  margin-bottom: 20px;
`;
const RequestWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .header {
    &__img {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 10px;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &__action {
      display: flex;
      button {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        font-weight: bold;
        font-size: 16px;
        &:first-of-type {
          color: #97d7d8;
        }
        span {
          margin-left: 5px;
          line-height: 1;
        }
      }
      a {
        color: #ec0707;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        span {
          margin-left: 5px;
          line-height: 1;
        }
      }
    }
  }
`;
const RequestBody = styled.div`
  margin-top: 20px;
  h2 {
    font-size: 24px;
  }
  p {
    color: #9298ac;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
  }
`;
const RequestFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
  }
  span {
    color: #97d7d8;
  }
`;
const QuotationForm = styled.div`
  background: #f9fafc;
  margin: 20px -20px -20px;
  padding: 0 20px 20px;
  border-radius: 0 0 18px 18px;

  .ant-form {
    display: flex;
    flex-wrap: wrap;
    .ant-form-item {
      width: 50%;
      padding-left: 15px;
      &:first-of-type {
        .ant-form-item-control {
          line-height: 1;
        }
      }
      &:nth-of-type(2n + 1) {
        padding-left: 0;
        padding-right: 15px;
      }
      &:nth-of-type(5) {
        width: 100%;
        padding: 0;
      }
      .ant-input {
        height: 40px;
      }
      label {
        font-size: 16px;
        font-weight: bold;
      }
      .ant-calendar-disabled-cell.ant-calendar-today
        .ant-calendar-date::before {
        left: 2px;
        width: 50px;
      }
      .ant-upload-select {
        width: 100%;
        height: 40px;
        cursor: pointer;
        .ant-upload {
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .ant-calendar-picker {
      width: 100%;
    }
    .ant-input-group {
      & > .ant-input-group-addon {
        width: 25%;
        padding: 0;
        height: 40px;
        .ant-input {
          border: none;
          height: 100%;
        }
      }
    }
    .ant-upload-list {
      display: none;
    }
    .text-area {
      min-height: 150px;
      font-family: Heebo;
      font-size: 17px;
      font-weight: 500;
    }
    .primary-button {
      min-width: 100%;
    }
  }
`;

const FileNameWrapper = styled.div`
  border: 2px dashed #000;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #000;
  }
`;
const WrongFileWrapper = styled.div`
  border: 1px solid #ec0707;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: #ec0707;
  }
`;

export class RequestQuotation extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    rejectRequest: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    sendQuotation: PropTypes.func.isRequired,
    generateShareToken: PropTypes.func.isRequired,
    readings: PropTypes.array,
  };
  state = {
    showForm: false,
    selectedFile: null,
    wrongFile: false,
  };

  beforeUpload = (rule, file, callback) => {
    const isPdf = file.file.type === 'application/pdf';
    const isLt5M = file.file.size / 1024 / 1024 < 5;
    this.setState({ selectedFile: file.file });
    if (!isPdf) {
      this.setState({ wrongFile: true });
      callback('You can only upload PDF file!');
    } else if (!isLt5M) {
      this.setState({ wrongFile: true });
      callback('Image must smaller than 5MB!');
    } else {
      this.setState({ wrongFile: false });
      callback();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields },
      sendQuotation,
      id,
    } = this.props;

    validateFields((err, values) => {
      if (!err) {
        const quotation = {
          ...values,
          earliestCheckInTime: moment(values.earliestCheckInTime).unix(),
        };
        sendQuotation(id, quotation);
      }
    });
  };
  render() {
    const {
      request,
      rejectRequest,
      form: { getFieldDecorator },
      generateShareToken,
    } = this.props;

    const { showForm, selectedFile, wrongFile } = this.state;
    const startDate = convertDateToFullStringDate(request.createdAt);
    const preferredDate = request.preferredCheckInDate
      ? moment(new Date(request.preferredCheckInDate * 1000)).format(
          'MMM DD, YYYY'
        )
      : 'No preferred check in';
    const dateFormat = 'MM/DD/YYYY';

    return (
      <RequestQuotationWrapper>
        <RequestWrapper>
          <div className="header__img">
            <ProfilePic user={request.patient} width="50" height="50" />
            <p>
              {request.patient.firstName} {request.patient.middleName}{' '}
              {request.patient.lastName}
            </p>
          </div>
          <div className="header__action">
            <button
              onClick={() => {
                this.setState({ showForm: true });
              }}
            >
              <CheckIcon />
              <span>Accept</span>
            </button>
            <Link
              to="/"
              onClick={() => {
                rejectRequest(request.id);
              }}
            >
              <CloseIcon /> <span>Cancel</span>
            </Link>
          </div>
        </RequestWrapper>
        <RequestBody>
          <H2>{request.note}</H2>
          <p>Sent on: {startDate}</p>
        </RequestBody>
        <RequestFooter>
          <p>
            Preferred Check in data: <span>{preferredDate}</span>
          </p>
          {request && (
            <PrimaryButton
              onClick={() => {
                generateShareToken(request.patient.id, {
                  reservationTitle: request.note,
                  consultation: request.patientConsultationRequest,
                });
              }}
            >
              <button>Share with designated team</button>
            </PrimaryButton>
          )}
        </RequestFooter>
        {showForm && (
          <QuotationForm>
            <Form onSubmit={this.handleSubmit}>
              <FormItem label="Quotation">
                {getFieldDecorator('quotationFile', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Quotation File!',
                    },
                    {
                      validator: this.beforeUpload,
                    },
                  ],
                })(
                  <Upload
                    htmltype="button"
                    accept="application/pdf,application/vnd.ms-excel"
                  >
                    {selectedFile ? (
                      wrongFile ? (
                        <WrongFileWrapper>
                          <p>{selectedFile.name}</p>
                        </WrongFileWrapper>
                      ) : (
                        <FileNameWrapper>
                          <p>{selectedFile.name}</p>
                        </FileNameWrapper>
                      )
                    ) : (
                      <>
                        <Icon type="upload" /> Upload
                      </>
                    )}
                  </Upload>
                )}
              </FormItem>
              <FormItem label="Price">
                {getFieldDecorator('price', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your price!',
                    },
                  ],
                })(
                  <Input
                    type="number"
                    placeholder="Price ex: 1000£"
                    addonAfter="GBP"
                  />
                )}
              </FormItem>
              <FormItem label="Duration of Stay">
                {getFieldDecorator('durationInDays', {
                  rules: [
                    {
                      required: true,
                      message: 'Please Input The Duration of Stay!',
                    },
                  ],
                })(
                  <Input
                    type="number"
                    placeholder="Duration of Stay In days ex: 12"
                    addonAfter="Days"
                  />
                )}
              </FormItem>
              <FormItem label="Earliest check in">
                {getFieldDecorator('earliestCheckInTime', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Earliest check in!',
                    },
                  ],
                })(
                  <DatePicker disabledDate={disabledDate} format={dateFormat} />
                )}
              </FormItem>
              {/* <FormItem label="Notes">
                {getFieldDecorator(
                  'note',
                  {}
                )(<Input.TextArea placeholder="Note" className="text-area" />)}
              </FormItem> */}
              <PrimaryButton>
                <button htmltype="submit">Send</button>
              </PrimaryButton>
            </Form>
          </QuotationForm>
        )}
      </RequestQuotationWrapper>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  generateShareToken: (id, sharedDate) =>
    dispatch(patientActions.generateShareToken(id, sharedDate)),
});

export default Form.create({ name: 'request_quotation_form' })(
  connect(mapStateToProps, mapDispatchToProps)(RequestQuotation)
);
