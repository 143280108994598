import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import { Conditions } from './components';

function ConditionsPage(props) {
  return (
    <MainLayout footer="main" children={<Conditions {...props} />} {...props} />
  );
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(ConditionsPage);
