/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

/** icon for logout used mainly in menu */
const ConcernIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="73"
      viewBox="0 0 105 73"
      css={css`
        max-width: 100%;
        height: 73px;
        width: 105px;
      `}
    >
      <g fill="none">
        <path
          fill="#FFF"
          d="M49.649 33.07c2.567 0 3.836 2.415 3.836 4.508 0 5.361-7.674 10.301-7.674 10.301s-7.676-4.906-7.676-10.3c0-2.093 1.035-4.512 3.838-4.512 2.12 0 3.838 1.699 3.838 3.793 0-1.006.404-1.97 1.124-2.68.72-.712 1.696-1.11 2.714-1.11z"
          transform="translate(23.841 8.564)"
        />
        <path
          fill="#2B90BE"
          d="M45.177 54.787c-4.336-.19-8.357-2.286-10.963-5.714-2.605-3.428-3.52-7.828-2.496-11.994.183-.748.428-1.48.731-2.188 2.83-6.61 10.174-10.133 17.183-8.241 7.008 1.891 11.519 8.613 10.553 15.726-.965 7.114-7.109 12.424-14.374 12.425-.213 0-.424-.004-.634-.014zm8.308-17.209c0-2.093-1.269-4.508-3.836-4.508-1.018 0-1.994.398-2.714 1.11-.72.71-1.124 1.674-1.124 2.68 0-2.094-1.718-3.792-3.838-3.793-2.803 0-3.838 2.42-3.838 4.511 0 5.395 7.676 10.301 7.676 10.301s7.674-4.94 7.674-10.3z"
          transform="translate(23.841 8.564)"
        />
        <path
          fill="#97d7d8"
          d="M45.177 54.787H3.413c-1.325 0-3.297-1.704-3.297-3.013v-5.582c0-7.89 9.856-9.646 16.187-11.486l.002.004c0 2.205 2.65 6.783 7.947 6.783 3.717 0 6.356-2.338 7.465-4.414h.001c-1.025 4.166-.11 8.566 2.496 11.994 2.606 3.428 6.627 5.524 10.963 5.714zM35.45 9.793c0 .275-.054 1.217-.144 2.32 0-3.808-4.431-6.213-4.431-6.213s-1.059 3.984-8.76 7.425c-7.98 3.565-9.68-2.019-9.762-2.303l-.003-.012c-.069-.338-.119-.6-.14-.741C11.872 7.984 13.572.059 24.047.059c9.803 0 11.403 7.199 11.403 9.734z"
          transform="translate(23.841 8.564)"
        />
        <path
          fill="#D4DAEB"
          d="M32.45 34.89c-.304.71-.549 1.441-.732 2.19h-.001c-1.11 2.075-3.748 4.413-7.465 4.413-5.297 0-7.947-4.578-7.947-6.783l-.002-.004c.4-.117.782-.233 1.15-.35l1.593-3.392.207-.214c1.24.774 2.866 1.287 5 1.287 2.129 0 3.752-.498 4.992-1.252l.009.016 1.62 3.601c.503.168 1.03.328 1.575.489z"
          transform="translate(23.841 8.564)"
        />
        <path
          fill="#D4DAEB"
          d="M34.634 16.622c1.418-1.594 3.316-.178 2.79 2.025-.66 2.759-2.455 4.945-4.192 4.913-.007 0-.114 4.858-3.978 7.22l-.01.005c-1.24.754-2.862 1.252-4.992 1.252-2.133 0-3.76-.513-5-1.287-3.8-2.37-3.973-7.19-3.98-7.19-1.737.032-3.534-2.154-4.193-4.913-.527-2.203 1.372-3.5 2.79-2.026-.357-.464-1.175-3.942-1.516-5.599.081.284 1.781 5.868 9.762 2.303 7.701-3.441 8.76-7.425 8.76-7.425s4.431 2.405 4.431 6.214c-.149 1.852-.401 4.157-.672 4.508z"
          transform="translate(23.841 8.564)"
        />
      </g>
    </svg>
  );
};

export default ConcernIcon;
