import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';
class Condition extends Component {
  componentDidMount() {
    const {
      getCondition,

      match: {
        params: { id },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    if (id && patientId) {
      getCondition(id, patientId);
    }
  }
  render() {
    const {
      report: condition,
      match: {
        params: { requestId, invitationId, id },
        path,
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      requestId && patientId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
            {
              item: 'Conditions',
              url: `/request/${requestId}/conditions/${patientId}`,
            },
          ]
        : invitationId && patientId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
            {
              item: 'Conditions',
              url: `/invited-patient/${invitationId}/conditions/${patientId}`,
            },
          ]
        : [];

    return (
      <Container>
        {condition && condition.id === Number(id) && (
          <MedicalReport
            title={condition.value}
            updatedAt={condition.updatedAt}
            description={condition.description || condition.note}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={requestId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getCondition: (id, patientId) =>
    dispatch(patientReportsActions.getCondition(id, patientId)),
});

Condition.propTypes = {
  getCondition: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Condition);
