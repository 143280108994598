import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import AllergyComponents from './Allergy';

function AllergyPage(props) {
  return (
    <MainLayout
      footer="main"
      children={<AllergyComponents {...props} />}
      {...props}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(AllergyPage);
