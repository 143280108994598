import { alertActions } from 'redux/actions';
import { invitePatientServices } from 'services';
import { history } from 'utils';
import invitePatientConstants from './types';

const getInvitedPatient = () => {
  return (dispatch) => {
    dispatch(getInvitedPatientRequest());
    invitePatientServices.getInvitedPatient().then((res) => {
      if (res.error) {
        dispatch(getInvitedPatientFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(getInvitedPatientSuccess(res.requests));
      }
    });
  };
};

const getInvitedPatientRequest = () => ({
  type: invitePatientConstants.GET_INVITED_PATIENT_REQUEST,
});

const getInvitedPatientSuccess = (requests) => ({
  type: invitePatientConstants.GET_INVITED_PATIENT_SUCCESS,
  requests,
});

const getInvitedPatientFailure = (error) => ({
  type: invitePatientConstants.GET_INVITED_PATIENT_FAILURE,
  error,
});

const showInvitedPatient = (id) => {
  return (dispatch) => {
    dispatch(showInvitedPatientRequest());
    invitePatientServices.showInvitedPatient(id).then((res) => {
      if (res.error) {
        dispatch(showInvitedPatientFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(showInvitedPatientSuccess(res.request));
      }
    });
  };
};

const showInvitedPatientRequest = () => ({
  type: invitePatientConstants.SHOW_INVITED_PATIENT_REQUEST,
});

const showInvitedPatientSuccess = (request) => ({
  type: invitePatientConstants.SHOW_INVITED_PATIENT_SUCCESS,
  request,
});

const showInvitedPatientFailure = (error) => ({
  type: invitePatientConstants.SHOW_INVITED_PATIENT_FAILURE,
  error,
});

const invitePatient = (invitedPatient) => {
  return (dispatch) => {
    dispatch(invitePatientRequest());
    invitePatientServices.invitePatient(invitedPatient).then((res) => {
      if (res.error) {
        dispatch(invitePatientFailure(res.errors));
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(alertActions.clear());
      } else {
        dispatch(invitePatientSuccess(res.request));
        history.push('/hospital-requests');
      }
    });
  };
};

const invitePatientRequest = () => ({
  type: invitePatientConstants.INVITE_PATIENT_REQUEST,
});

const invitePatientSuccess = (request) => ({
  type: invitePatientConstants.INVITE_PATIENT_SUCCESS,
  request,
});

const invitePatientFailure = (error) => ({
  type: invitePatientConstants.INVITE_PATIENT_FAILURE,
  error,
});

const getConsultationType = () => {
  return (dispatch) => {
    dispatch(getConsultationTypeRequest());

    invitePatientServices.getConsultationType().then((res) => {
      if (res.error) {
        res.errors.map((err) => dispatch(alertActions.error(err)));
        dispatch(getConsultationTypeFailure(res.errors));
      } else {
        dispatch(getConsultationTypeSuccess(res.consultationTypes));
      }
    });
  };
};

const getConsultationTypeRequest = () => ({
  type: invitePatientConstants.GET_CONSULTATION_TYPE_REQUEST,
});

const getConsultationTypeSuccess = (consultationType) => {
  return {
    type: invitePatientConstants.GET_CONSULTATION_TYPE_SUCCESS,
    consultationTypes: consultationType,
  };
};

const getConsultationTypeFailure = (error) => ({
  type: invitePatientConstants.GET_CONSULTATION_TYPE_FAILURE,
  error,
});

const invitePatientActions = {
  getInvitedPatient,
  showInvitedPatient,
  invitePatient,
  getConsultationType,
};

export default invitePatientActions;
