import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import { ProfilePic, convertDateToFullStringDate } from '@mous221/hk-utils';
import { CloseIcon, CheckIcon } from 'images';

const ActiveRequestWrapper = styled.div`
  margin-bottom: 15px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
`;
const ActiveRequestHeader = styled.header`
  background-color: #f9fafc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 6px 6px 0 0;
`;
const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  p {
    margin-bottom: 0;
    line-height: 24px;
    vertical-align: middle;
    margin-left: 10px;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
  }
`;

const ActiveRequestContent = styled.div`
  background-color: #fff;
  padding: 16px;
  font-family: AvenirNext, Arial, sans-serif;
  .active-consultant {
    &__time {
      font-size: 14px;
      color: #9298ac;
      margin-bottom: 15px;
    }
    &__title {
      font-size: 18px;
      font-weight: bold;
      color: #060c2b;
    }
  }
  .short-text {
    font-size: 16px;
    font-weight: 500;
    color: #9298ac;
    margin-bottom: 0;
  }
  .readMoreText {
    color: #97d7d8 !important;
  }
`;

const RequestActions = styled.div`
  display: flex;
  div {
    a {
      display: flex;
      align-items: center;
      color: #97d7d8;
      font-size: 15px;
      span {
        margin-left: 5px;
        line-height: 1;
      }
    }
    & + div {
      margin-left: 15px;
      a {
        color: #ec0707;
      }
    }
  }
`;
export default class ActiveRequest extends Component {
  render() {
    const {
      handelShowModal,
      request,
      rejectRequest,
      setCancelRequestsid,
    } = this.props;
    const startDate = convertDateToFullStringDate(request.createdAt);

    return (
      <ActiveRequestWrapper>
        <ActiveRequestHeader>
          <ImageWrapper>
            <ProfilePic user={request.patient} />
            <p>
              {request.patient.firstName} {request.patient.middleName}{' '}
              {request.patient.lastName}
            </p>
          </ImageWrapper>

          <RequestActions>
            <div>
              <Link
                to={`/request/${request.id}?patientId=${request.patient.id}`}
              >
                <CheckIcon />
                <span>View</span>
              </Link>
            </div>
            <div>
              <Link
                to="#!"
                onClick={() => {
                  rejectRequest(request.id);
                  handelShowModal('Cancel');
                  setCancelRequestsid(request.id);
                }}
              >
                <CloseIcon /> <span>Reject</span>
              </Link>
            </div>
          </RequestActions>
        </ActiveRequestHeader>
        <ActiveRequestContent>
          <h3 className="active-consultant__title">{request.note}</h3>
          <p className="active-consultant__time">Sent on: {startDate}</p>
        </ActiveRequestContent>
      </ActiveRequestWrapper>
    );
  }
}

ActiveRequest.propTypes = {
  notAnswerYet: PropTypes.bool,
  handelShowModal: PropTypes.func,
  request: PropTypes.object.isRequired,
  rejectRequest: PropTypes.func,
  setCancelRequestsid: PropTypes.func,
};
