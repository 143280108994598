import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { patientReportsActions } from 'redux/actions';
import { Breadcrumb, H1, Pagination, StateCard } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';

const CTsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class CTs extends Component {
  componentDidMount() {
    const {
      match: {
        params: { patientId },
      },
      getSharedCTs,
    } = this.props;

    if (patientId) {
      getSharedCTs(patientId);
    }
  }

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedCTs,
    } = this.props;

    if (patientId) {
      getSharedCTs(patientId, null, page);
    }
  };

  render() {
    const {
      reports: cts,
      match: {
        params: { requestId, patientId, invitationId },
        path,
      },
    } = this.props;
    const BreadcrumbLinks =
      requestId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
          ]
        : invitationId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}`,
            },
          ]
        : [];
    return (
      <Container>
        <CTsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>CT</H1>

          {cts &&
            cts.data.map((report) => {
              let fileUrls = '';
              report.files.map((file, index) =>
                index === 0
                  ? (fileUrls = file.file_url)
                  : (fileUrls = `${fileUrls},${file.file_url}`)
              );

              return (
                <StateCard
                  location={`https://mri.holdenknight.com/u-dicom-viewer?fileUrls=${fileUrls}`}
                  key={report.createdAt}
                  title={report.title}
                  time={report.createdAt}
                  patientId={patientId}
                />
              );
            })}
          {cts && cts.meta.total > cts.meta.per_page && (
            <Pagination
              pageSize={cts.meta.per_page}
              total={cts.meta.total}
              setPage={this.setPage}
            />
          )}
        </CTsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedCTs: (patientId, sharedToken, page = null) =>
    dispatch(patientReportsActions.getSharedCTs(patientId, sharedToken, page)),
});

CTs.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedCTs: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CTs);
