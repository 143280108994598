import { patientReportsConstants } from 'redux/actions/types';

const initialState = {
  loading: false,
  report: {},
  reports: { data: [] },
  xray: {},
  xrays: { data: [] },
  mri: {},
  mris: { data: [] },
  ct: {},
  cts: { data: [] },
  allergy: {},
  allergies: { data: [] },
  condition: {},
  conditions: { data: [] },
  ecg: {},
  ecgs: { data: [] },
  labResult: {},
  labResults: { data: [] },
  medicalReport: {},
  medicalReports: { data: [] },
  radiology: {},
  radiologies: { data: [] },
  readings: null,
};

export default function patientReports(state = initialState, action) {
  switch (action.type) {
    case patientReportsConstants.GET_XRAY_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_XRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        xray: action.xray,
        report: action.xray,
      };
    case patientReportsConstants.GET_XRAY_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_XRAYS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_XRAYS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        xrays: action.xrays,
        reports: action.xrays,
      };
    case patientReportsConstants.GET_SHARED_XRAYS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };
    case patientReportsConstants.GET_MRI_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_MRI_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        mri: action.mri,
        report: action.mri,
      };
    case patientReportsConstants.GET_MRI_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_MRIS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_MRIS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        mris: action.mris,
        reports: action.mris,
      };
    case patientReportsConstants.GET_SHARED_MRIS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };
    case patientReportsConstants.GET_CT_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_CT_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        ct: action.ct,
        report: action.ct,
      };
    case patientReportsConstants.GET_CT_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_CTS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_CTS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        cts: action.cts,
        reports: action.cts,
      };
    case patientReportsConstants.GET_SHARED_CTS_FAILURE:
      return {
        loading: false,
        fetchingReports: false,
        error: action.error,
      };
    case patientReportsConstants.GET_ALLERGY_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_ALLERGY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        allergy: action.allergy,
        report: action.allergy,
      };
    case patientReportsConstants.GET_ALLERGY_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_ALLERGIES_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_ALLERGIES_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        allergies: action.allergies,
        reports: action.allergies,
      };
    case patientReportsConstants.GET_SHARED_ALLERGIES_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_CONDITION_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_CONDITION_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        report: action.condition,
        condition: action.condition,
      };
    case patientReportsConstants.GET_CONDITION_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_CONDITIONS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_CONDITIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        reports: action.conditions,
        conditions: action.conditions,
      };
    case patientReportsConstants.GET_SHARED_CONDITIONS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_ECG_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_ECG_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        report: action.ecg,
        ecg: action.ecg,
      };
    case patientReportsConstants.GET_ECG_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_ECGS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_ECGS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        reports: action.ecgs,
        ecgs: action.ecgs,
      };
    case patientReportsConstants.GET_SHARED_ECGS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_LAB_RESULT_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_LAB_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        report: action.labResult,
        labResult: action.labResult,
      };
    case patientReportsConstants.GET_LAB_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_LAB_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_LAB_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        reports: action.labResults,
        labResults: action.labResults,
      };
    case patientReportsConstants.GET_SHARED_LAB_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        report: action.medicalReport,
        medicalReport: action.medicalReport,
      };
    case patientReportsConstants.GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        reports: action.medicalReports,
        medicalReports: action.medicalReports,
      };
    case patientReportsConstants.GET_SHARED_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_RADIOLOGY_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReport: true,
      };
    case patientReportsConstants.GET_RADIOLOGY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        report: action.radiology,
        radiology: action.radiology,
      };
    case patientReportsConstants.GET_RADIOLOGY_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReport: false,
        error: action.error,
      };
    case patientReportsConstants.GET_SHARED_RADIOLOGIES_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_SHARED_RADIOLOGIES_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        reports: action.radiologies,
        radiologies: action.radiologies,
      };
    case patientReportsConstants.GET_SHARED_RADIOLOGIES_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };

    case patientReportsConstants.GET_PATIENT_READING_REQUEST:
      return {
        ...state,
        loading: true,
        fetchingReports: true,
      };
    case patientReportsConstants.GET_PATIENT_READING_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        reports: action.readings,
        readings: action.readings,
      };
    case patientReportsConstants.GET_PATIENT_READING_FAILURE:
      return {
        ...state,
        loading: false,
        fetchingReports: false,
        error: action.error,
      };
    case patientReportsConstants.CLEAR_DATA:
      return {
        loading: false,
        report: {},
        reports: { data: [] },
        xray: {},
        xrays: { data: [] },
        mri: {},
        mris: { data: [] },
        ct: {},
        cts: { data: [] },
        allergy: {},
        allergies: { data: [] },
        condition: {},
        conditions: { data: [] },
        ecg: {},
        ecgs: { data: [] },
        labResult: {},
        labResults: { data: [] },
        medicalReport: {},
        medicalReports: { data: [] },
        radiology: {},
        radiologies: { data: [] },
        readings: null,
      };
    default:
      return state;
  }
}
