/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

const HospitalIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="204"
      height="201"
      viewBox="0 0 204 201"
      css={css`
        fill: ${color || '#f0ba11'};
        max-width: ${width || '204'}px;
        max-height: ${height || '201'}px;
        height: ${height || '201'}px;
        width: ${width || '204'}px;
      `}
    >
      <defs>
        <path
          id="a"
          d="M106.214636,0 L106.214636,158.124198 L0,158.124198 C24.3969907,121.803952 48.7939813,85.483706 73.190972,49.1634598 C84.1985976,32.775771 95.2070103,16.3876888 106.214636,0"
        />
        <path
          id="c"
          d="M0.00826309701,3.04947628 C0.00550873134,3.24779061 0.00314784648,3.44531797 0,3.6436323 L0,3.6436323 C0,3.6436323 0.533166497,3.38905022 0.907366748,3.126992 L0.907366748,3.126992 C1.08403963,3.00422598 1.22451228,2.87909909 1.25795815,2.77876148 L1.25795815,2.77876148 C1.35160658,2.49781618 0.818440085,1.69511533 0.255762526,0.462733432 L0.255762526,0.462733432 C0.188477308,0.314784648 0.120011647,0.16054017 0.0527264285,0 L0.0527264285,0 C0.0373806769,1.01636093 0.022821887,2.03311534 0.00826309701,3.04947628"
        />
      </defs>
      <g fill="none" fill-rule="evenodd" transform="translate(-21.577 .5)">
        <g transform="translate(21.724)">
          <path
            fill="#B0D2F0"
            d="M197.635456,145.060422 L200.77858,141.688291 C201.425463,142.584641 201.904329,144.433607 200.997749,145.917816 C202.924231,147.700678 201.966499,148.778422 201.966499,148.778422 L199.78937,146.885779 C199.78937,146.885779 202.038506,149.071565 202.085724,149.206923 C201.735919,150.071793 201.011128,150.036774 201.011128,150.036774 L199.138946,147.324904 L200.854522,150.152063 C200.455533,150.746613 199.822422,150.80052 199.822422,150.80052 L197.635456,145.060422 Z"
          />
          <path
            fill="#30B8BA"
            d="M148.548331,64.619133 C144.644215,64.619133 140.763313,63.5799502 136.94891,61.5070933 C130.066931,57.7670582 125.838586,52.0053187 124.720314,44.8455419 C122.998835,33.8198161 128.691322,20.2116758 139.948021,8.44345172 L141.890636,10.3018616 C131.209599,21.468847 125.783892,34.2270688 127.376703,44.4304196 C128.37457,50.8217284 132.027252,55.772504 138.232445,59.145028 C143.542862,62.0312097 148.978406,62.6733704 154.387586,61.0538034 C168.151545,56.9324854 177.536063,39.6386103 177.628924,39.4642983 L180.000039,40.732487 C179.597508,41.4840353 169.983592,59.1906718 155.158809,63.6291353 C152.953349,64.2893961 150.747102,64.619133 148.548331,64.619133"
            opacity=".387"
          />
          <path
            fill="#171A1B"
            d="M109.409307,198.504835 L7.18771396,198.504835 C3.21788606,198.504835 0,195.286949 0,191.317121 L0,37.1859657 C0,33.2165313 3.21788606,29.9986453 7.18771396,29.9986453 L109.409307,29.9986453 C113.379134,29.9986453 116.597021,33.2165313 116.597021,37.1859657 L116.597021,191.317121 C116.597021,195.286949 113.379134,198.504835 109.409307,198.504835"
          />
          <path
            fill="#30B8BA"
            d="M111.805172,198.504835 L9.58357926,198.504835 C5.61375137,198.504835 2.3958653,195.286949 2.3958653,191.317121 L2.3958653,37.1859657 C2.3958653,33.2165313 5.61375137,29.9986453 9.58357926,29.9986453 L111.805172,29.9986453 C115.775,29.9986453 118.992886,33.2165313 118.992886,37.1859657 L118.992886,191.317121 C118.992886,195.286949 115.775,198.504835 111.805172,198.504835"
          />
          <polygon
            fill="#FFF"
            points="7.587 191.317 113.802 191.317 113.802 33.193 7.587 33.193"
          />
          <polygon
            fill="#42B79E"
            points="30.996 43.309 81.974 43.309 81.974 27.603 30.996 27.603"
          />
          <path
            fill="#24A086"
            d="M49.9297243,26.4047489 L66.2347821,26.4047489 L66.2347821,20.8145671 L49.9297243,20.8145671 L49.9297243,26.4047489 Z M46.9683878,27.602898 L69.1961187,27.602898 L69.1961187,19.2174285 L46.9683878,19.2174285 L46.9683878,27.602898 Z"
          />
          <path
            fill="#42B79E"
            d="M48.3325857,26.4047489 L64.6376435,26.4047489 L64.6376435,20.8145671 L48.3325857,20.8145671 L48.3325857,26.4047489 Z M45.3712491,27.602898 L67.5989801,27.602898 L67.5989801,19.2174285 L45.3712491,19.2174285 L45.3712491,27.602898 Z"
          />
          <polygon
            fill="#2CA88E"
            points="84.104 27.603 87.847 27.603 87.847 29.449 87.847 30.007 86.067 30.007 86.067 29.449 84.104 29.449 84.104 43.309 81.974 43.309 81.974 27.603"
          />
          <polygon
            fill="#ADDAFF"
            points="18.019 118.195 34.789 118.195 34.789 101.424 18.019 101.424"
          />
          <polygon
            fill="#FF664F"
            points="20.098 107.996 26.021 114.252 40.463 94.486 25.938 111.174"
          />
          <polygon
            fill="#ADDAFF"
            points="18.019 146.545 34.789 146.545 34.789 129.775 18.019 129.775"
          />
          <polygon
            fill="#FF664F"
            points="20.098 136.347 26.021 142.602 40.463 122.837 25.938 139.524"
          />
          <polygon
            fill="#ADDAFF"
            points="18.019 174.896 34.789 174.896 34.789 158.125 18.019 158.125"
          />
          <polygon
            fill="#FF664F"
            points="20.098 164.697 26.021 170.953 40.463 151.187 25.938 167.875"
          />
          <polygon
            fill="#ADDAFF"
            points="39.93 104.203 59.297 104.203 59.297 101.54 39.93 101.54"
          />
          <polygon
            fill="#ADDAFF"
            points="39.931 110.192 105.683 110.192 105.683 107.53 39.931 107.53"
          />
          <polygon
            fill="#ADDAFF"
            points="39.931 116.581 105.683 116.581 105.683 113.919 39.931 113.919"
          />
          <polygon
            fill="#ADDAFF"
            points="39.931 132.553 89.178 132.553 89.178 129.891 39.931 129.891"
          />
          <polygon
            fill="#ADDAFF"
            points="39.931 138.542 105.683 138.542 105.683 135.881 39.931 135.881"
          />
          <polygon
            fill="#ADDAFF"
            points="39.93 144.931 77.199 144.931 77.199 142.27 39.93 142.27"
          />
          <polygon
            fill="#ADDAFF"
            points="39.931 160.904 51.91 160.904 51.91 158.242 39.931 158.242"
          />
          <polygon
            fill="#ADDAFF"
            points="39.931 166.893 105.683 166.893 105.683 164.231 39.931 164.231"
          />
          <polygon
            fill="#ADDAFF"
            points="68.414 173.282 105.683 173.282 105.683 170.62 68.414 170.62"
          />
          <g transform="translate(7.587 33.193)">
            <mask id="b" fill="#fff">
              <use xlinkHref="#a" />
            </mask>
            <path
              d="M0,158.124198 L106.214636,158.124198 L106.214636,0 C95.2070103,16.3876888 84.1985976,32.775771 73.190972,49.1634598 C48.7939813,85.483706 24.3969907,121.803952 0,158.124198"
              mask="url(#b)"
            />
          </g>
          <path
            fill="#42B79E"
            d="M188.384074,95.2430362 C188.384074,95.2430362 165.210394,78.249387 136.475671,78.249387 C107.740554,78.249387 84.5672469,95.2430362 84.5672469,95.2430362 C84.5672469,128.61257 84.2584067,180.813701 136.475671,198.734391 C188.692935,180.813701 188.384074,128.61257 188.384074,95.2430362"
          />
          <path
            fill="#21118D"
            d="M136.47571,192.831628 C116.345232,185.416089 103.201399,171.829984 96.326109,151.352062 C90.4219295,133.766224 90.1421646,114.289317 90.1299667,98.2109046 C92.5506607,96.6720011 96.7915968,94.1702501 102.299935,91.6956493 C110.304908,88.0988412 122.732606,83.8110808 136.47571,83.8110808 C158.109285,83.8110808 176.563142,94.2292722 182.821454,98.2089372 C182.808863,114.28735 182.529491,133.766224 176.624918,151.352062 C169.749628,171.829984 156.606189,185.416089 136.47571,192.831628"
          />
          <polygon
            fill="#FFF"
            points="127 166.955 145.952 166.955 145.952 101.448 127 101.448"
          />
          <polygon
            fill="#FFF"
            points="103.722 143.677 169.229 143.677 169.229 124.726 103.722 124.726"
          />
          <path
            fill="#21118D"
            d="M139.766902,11.6233669 L139.766902,11.6233669 C138.007256,9.93218636 137.951775,7.1345378 139.643349,5.37449814 L143.504183,1.35705907 C145.195757,-0.402587111 147.993012,-0.458461386 149.753051,1.23311262 C151.513091,2.92429314 151.568178,5.7219417 149.876998,7.48158788 L146.015771,11.4994204 C144.32459,13.2590666 141.526942,13.3145474 139.766902,11.6233669"
          />
          <path
            fill="#EBEBEB"
            d="M151.103281,4.54205014 L147.514736,8.27618303 C145.823162,10.0358292 143.025907,10.09131 141.265867,8.40012948 C140.33135,7.50181279 139.882389,6.2918593 139.915441,5.09095587 L139.643152,5.37465553 C137.951578,7.13430172 138.007453,9.93195027 139.766705,11.6235243 C141.526745,13.3147048 144.324393,13.259224 146.015574,11.4991843 L149.877195,7.48174527 C150.670058,6.65661601 151.074163,5.60248092 151.103281,4.54205014"
          />
          <path
            fill="#21118D"
            d="M176.769995,36.1001948 L180.631222,32.0827557 C182.322402,30.3231095 185.120051,30.2672353 186.879697,31.9588093 C188.639737,33.6499898 188.695218,36.4476384 187.003644,38.2072845 L183.14281,42.2251171 C181.451236,43.9847633 178.653981,44.0402441 176.893941,42.3490635 C175.133902,40.657883 175.078814,37.859841 176.769995,36.1001948 Z"
          />
          <path
            fill="#EBEBEB"
            d="M188.230359,35.2677468 L184.641421,39.0014862 C182.95024,40.7615259 180.152592,40.8170067 178.392552,39.1258261 C177.458429,38.2275094 177.009467,37.017556 177.042126,35.8166525 L176.769837,36.1003522 C175.078657,37.8599984 175.134138,40.6576469 176.893784,42.3492209 C178.653823,44.0404015 181.451079,43.9849207 183.142653,42.224881 L187.00388,38.2074419 C187.796744,37.3823127 188.201242,36.3281776 188.230359,35.2677468"
          />
          <polygon
            fill="#27374A"
            points="137.955 64.299 129.198 57.894 132.713 53.088 141.47 59.494"
          />
          <path
            fill="#27374A"
            d="M176.352157,197.420834 C173.820502,197.420834 171.248318,196.830613 168.687938,195.62971 C142.142542,183.17919 137.035555,185.398028 131.629129,187.747896 C131.255715,187.910403 130.881122,188.072911 130.500232,188.231484 L129.318609,185.395274 C129.68494,185.242603 130.044975,185.085998 130.404616,184.929786 C136.466188,182.295826 142.191727,179.80824 169.993114,192.8478 C180.137443,197.606164 187.515602,190.041889 191.010498,185.108426 C197.060266,176.569106 200.551227,163.743205 200.348978,150.799654 C200.121546,136.234175 195.267173,123.399618 186.680242,114.660409 C178.264081,106.095906 167.01171,99.6904318 157.083402,94.0392604 C144.301178,86.7630132 133.262074,80.4795182 131.016479,71.5042209 C129.949753,67.2412498 130.936996,62.7827187 134.03487,57.8740456 L136.633811,59.5136802 C134.00772,63.6747397 133.14521,67.3529983 133.997096,70.7581813 C135.923972,78.4589942 146.434238,84.4418699 158.603812,91.3690996 C168.705645,97.1194281 180.15633,103.637438 188.871536,112.506889 C198.014456,121.811529 203.181646,135.3937 203.421276,150.751649 C203.633362,164.324377 199.931102,177.832179 193.517758,186.884599 C188.611839,193.809861 182.609683,197.420834 176.352157,197.420834"
          />
          <path
            fill="#5C80AD"
            d="M141.303523,185.661188 C141.303523,193.57999 134.883884,199.999629 126.965083,199.999629 C119.045888,199.999629 112.626248,193.57999 112.626248,185.661188 C112.626248,177.742387 119.045888,171.322748 126.965083,171.322748 C134.883884,171.322748 141.303523,177.742387 141.303523,185.661188"
          />
          <path
            fill="#7CAEEB"
            d="M137.462875,185.661188 C137.462875,191.459128 132.762747,196.159256 126.964807,196.159256 C121.166867,196.159256 116.467133,191.459128 116.467133,185.661188 C116.467133,179.863249 121.166867,175.16312 126.964807,175.16312 C132.762747,175.16312 137.462875,179.863249 137.462875,185.661188"
          />
        </g>
        <g transform="translate(0 79.105)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="M0.00826309701,3.04947628 C0.00550873134,3.24779061 0.00314784648,3.44531797 0,3.6436323 L0,3.6436323 C0,3.6436323 0.533166497,3.38905022 0.907366748,3.126992 L0.907366748,3.126992 C1.08403963,3.00422598 1.22451228,2.87909909 1.25795815,2.77876148 L1.25795815,2.77876148 C1.35160658,2.49781618 0.818440085,1.69511533 0.255762526,0.462733432 L0.255762526,0.462733432 C0.188477308,0.314784648 0.120011647,0.16054017 0.0527264285,0 L0.0527264285,0 C0.0373806769,1.01636093 0.022821887,2.03311534 0.00826309701,3.04947628"
            mask="url(#d)"
          />
        </g>
      </g>
    </svg>
  );
};

export default HospitalIcon;

HospitalIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};
