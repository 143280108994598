import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import {
  Container,
  // MedicalReport
} from '@mous221/hk-components';
class MRI extends Component {
  componentDidMount() {
    const {
      getMRI,
      // match: {
      //   params: { id },
      // },
    } = this.props;
    getMRI();
  }

  /** this component is not used right now, we should have here an iframe for the other application with the dicom viewer */

  render() {
    // const {
    // mris,
    // patientId,
    // match: {
    //   params: { id },
    // },
    // } = this.props;
    let mri = {};
    // console.log("MRI -> render -> mris", mris)
    // if (mris && mris.data) {
    //   mri = mris.data.filter(mri => mri.id === id)[0];
    // }
    // console.log(mri);
    return (
      <Container>
        <>
          <div className="box-dicom-viewer-body"></div>
          <iframe
            title="box-title"
            src="https://app.box.com/embed/s/zm4h19eyt2mz9zamnya5ps9le9bd7c3s?sortColumn=date&view=list"
            width="800"
            height="550"
            frameBorder="0"
            allowFullScreen
          >
            <p>Box DICOM Viewer</p>
          </iframe>
          <iframe
            title="box-title"
            width="800"
            height="600"
            src="https://cloud.app.box.com/dicom_viewer/703402219071?toolbar=true"
            allowFullScreen
          >
            <p>Box DICOM Viewer</p>
          </iframe>
          {mri && (
            <>
              <h1>{mri.title}</h1>
            </>
          )}
        </>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMRI: () => dispatch(patientReportsActions.getMRI()),
});

MRI.propTypes = {
  getMRI: PropTypes.func,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MRI);
