import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

import {
  H1,
  convertDateToFullStringDate,
  PrimaryButton,
  ProfilePic,
} from '@mous221/hk-utils';

const DoctorReportWrapper = styled.div`
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  background: #fff;
  margin-top: 20px;
`;

const DoctorReportHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 20px 0 20px;

  .concern-header {
    &__doctor {
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 10px;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &__time {
      p {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }
    }
  }
`;
const DoctorReportContent = styled.div`
  padding: 20px;
  .concern-content {
    &__title {
      font-size: 20px;
      font-weight: bold;
      color: #060c2b;
      margin-bottom: 7px;
    }
    &__time {
      font-size: 14.4px;
      font-weight: normal;
      color: #9298ac;
    }
    &__body {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #060c2b;
      p + p {
        color: #97d7d8;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
`;

export default class DoctorReport extends Component {
  render() {
    const { request } = this.props;
    const hasFile = Boolean(
      request.patientConsultationRequest &&
        request.patientConsultationRequest.medicalReports &&
        request.patientConsultationRequest.medicalReports.length > 0 &&
        request.patientConsultationRequest.medicalReports[0].generatedFile
    );
    const hasDoctorReport = Boolean(
      request.patientConsultationRequest &&
        request.patientConsultationRequest.medicalReports &&
        request.patientConsultationRequest.medicalReports.length > 0
    );

    const doctorReport = hasDoctorReport
      ? request.patientConsultationRequest.medicalReports[0]
      : null;
    return (
      <>
        {doctorReport && (
          <DoctorReportWrapper>
            <DoctorReportHeader>
              <div className="concern-header__title">
                <H1>Doctor Report</H1>
                <div className="concern-header__doctor">
                  <ProfilePic
                    user={request.patientConsultationRequest.consultant}
                    width="25"
                    height="25"
                  />
                  {request.patientConsultationRequest.consultant && (
                    <p>
                      DR.
                      {request.patientConsultationRequest.consultant.name}
                    </p>
                  )}
                </div>
              </div>
              {hasFile && (
                <PrimaryButton>
                  <a
                    href={doctorReport.generatedFile}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download as PDF
                  </a>
                </PrimaryButton>
              )}
            </DoctorReportHeader>
            <DoctorReportContent>
              <h2 className="concern-content__title">{doctorReport.title}</h2>
              <p className="concern-content__time">
                {convertDateToFullStringDate(doctorReport.updatedAt)}
              </p>
              <div className="concern-content__body">
                <p>{doctorReport.note}</p>
              </div>
            </DoctorReportContent>
          </DoctorReportWrapper>
        )}
      </>
    );
  }
}

DoctorReport.propTypes = {
  request: PropTypes.object.isRequired,
};
