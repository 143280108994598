import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { H1 } from '@mous221/hk-utils';
import { patientActions } from 'redux/actions';
import {
  // SearchForm,
  ResultTable,
} from './';
import { connect } from 'react-redux';

const ReportsWrapper = styled.div``;
const ReportsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    line-height: 60px;
    vertical-align: middle;
    margin-right: 20px;
  }
  form {
    display: flex;
    & > div {
      margin-bottom: 10px;
      margin-right: 30px;
      input {
        width: 350px;
        height: 50px;
      }
    }
    button {
      max-height: 48px;
    }
  }
`;
class Reports extends Component {
  componentDidMount() {
    const { getBookedAndCanceledRequests } = this.props;
    getBookedAndCanceledRequests();
  }
  state = {
    searchDate: null,
    name: null,
  };
  setPage = (page) => {
    const { getBookedAndCanceledRequests } = this.props;
    const { searchDate, name } = this.state;

    getBookedAndCanceledRequests(page, name, searchDate);
  };
  setSearchDate = (date) => this.setState({ searchDate: date });
  setName = (name) => this.setState({ name });
  handleSearch = (page = null, name = null, date = null) =>
    this.props.getBookedAndCanceledRequests(page, name, date);

  render() {
    const { finishedRequests, loading } = this.props;
    console.log(finishedRequests);

    return (
      <ReportsWrapper>
        <ReportsHeader>
          <H1>Reports</H1>
          {/* <SearchForm
            handleSearch={this.handleSearch}
            setSearchDate={this.setSearchDate}
            setName={this.setName}
          /> */}
        </ReportsHeader>
        {finishedRequests && (
          <ResultTable
            loading={loading}
            finishedRequests={finishedRequests}
            setPage={this.setPage}
          />
        )}
      </ReportsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, finishedRequests } = state.patient;

  return {
    finishedRequests,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getBookedAndCanceledRequests: (page = null, name = null, date = null) =>
    dispatch(patientActions.getBookedAndCanceledRequests(page, name, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);

Reports.propTypes = {
  finishedRequests: PropTypes.object,
  getBookedAndCanceledRequests: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
