const invitePatientConstants = {
  GET_INVITED_PATIENT_REQUEST: 'GET_INVITED_PATIENT_REQUEST',
  GET_INVITED_PATIENT_SUCCESS: 'GET_INVITED_PATIENT_SUCCESS',
  GET_INVITED_PATIENT_FAILURE: 'GET_INVITED_PATIENT_FAILURE',

  SHOW_INVITED_PATIENT_REQUEST: 'SHOW_INVITED_PATIENT_REQUEST',
  SHOW_INVITED_PATIENT_SUCCESS: 'SHOW_INVITED_PATIENT_SUCCESS',
  SHOW_INVITED_PATIENT_FAILURE: 'SHOW_INVITED_PATIENT_FAILURE',

  INVITE_PATIENT_REQUEST: 'INVITE_PATIENT_REQUEST',
  INVITE_PATIENT_SUCCESS: 'INVITE_PATIENT_SUCCESS',
  INVITE_PATIENT_FAILURE: 'INVITE_PATIENT_FAILURE',

  GET_CONSULTATION_TYPE_REQUEST: 'GET_CONSULTATION_TYPE_REQUEST',
  GET_CONSULTATION_TYPE_SUCCESS: 'GET_CONSULTATION_TYPE_SUCCESS',
  GET_CONSULTATION_TYPE_FAILURE: 'GET_CONSULTATION_TYPE_FAILURE',
};

export default invitePatientConstants;
