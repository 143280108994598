import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patientActions } from 'redux/actions';
import styled from '@emotion/styled/macro';
import { Message, Message2X } from 'images';
import { H1 } from '@mous221/hk-utils';
import BookedRequest from './Quotation';
import { Spin } from 'antd';
import { Container } from '@mous221/hk-components';

const SendedQuotationsWrapper = styled.div`
  margin-bottom: 30px;

  & > div {
    max-width: 800px;
    margin: auto;
    & > h1 {
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  background: #fff;
  padding: 20px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  border-radius: 6px;
  margin-bottom: 15px;
`;
const ImgWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  margin-right: 20px;
  img {
    width: 49px;
    height: 49px;
  }
`;
const ContentWrapper = styled.div`
  max-width: 420px;
  h2 {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #97d7d8;
    margin: 0;
  }
  p {
    margin: 0;
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #060c2b;
  }
`;

const OffersWrapper = styled.div`
  header {
    background: #fff;
    padding-bottom: 40px;
    box-shadow: 1px 1px 2px 1px rgba(3, 3, 4, 0);
    h1 {
      font-size: 30px;
      color: #97d7d8;
    }
  }
  .offers__container {
    max-width: 770px;
    margin: auto;
    padding: 30px 0;
  }
`;
export class SendedQuotations extends Component {
  componentDidMount() {
    const { getSendedQuotationRequests } = this.props;
    getSendedQuotationRequests();
  }
  static propTypes = {
    sendedQuotations: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    getSendedQuotationRequests: PropTypes.func.isRequired,
  };

  render() {
    const { sendedQuotations, loading } = this.props;
    return (
      <SendedQuotationsWrapper>
        <Container>
          <>
            <H1>Sent Quotations</H1>
            <Spin spinning={loading}>
              {(!sendedQuotations ||
                (sendedQuotations && sendedQuotations.data.length < 1)) && (
                <HeaderWrapper>
                  <ImgWrapper>
                    <picture>
                      <source srcSet={`${Message2X} 2x`} />
                      <img src={Message} alt="Message" />
                    </picture>
                  </ImgWrapper>
                  <ContentWrapper>
                    <h2>There are no sent quotations</h2>
                    <p>Please check back later.</p>
                  </ContentWrapper>
                </HeaderWrapper>
              )}

              <OffersWrapper>
                <div className="offers__container">
                  {sendedQuotations &&
                    sendedQuotations.data.map((request) => (
                      <BookedRequest
                        key={JSON.stringify(request)}
                        request={request}
                        id={request.id}
                      />
                    ))}
                </div>
              </OffersWrapper>
            </Spin>
          </>
        </Container>
      </SendedQuotationsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, sendedQuotations } = state.patient;
  return {
    loading,
    sendedQuotations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSendedQuotationRequests: () =>
    dispatch(patientActions.getSendedQuotationRequests()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendedQuotations);
