import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { H2, ProfilePic, convertDateToFullStringDate } from '@mous221/hk-utils';
import styled from '@emotion/styled';
import { NoteIcon } from 'images';
import { history } from 'utils';

const BookedRequestWrapper = styled.div`
  background: #fff;
  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  margin: 0;
  margin-bottom: 20px;
  display: flex;
`;

const PatientWrapper = styled.div`
  width: 50%;
  padding: 30px;
  border-radius: 18px 0 0 18px;
  .patient {
    &__img {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 10px;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &__content {
      margin-top: 20px;
      h2 {
        font-size: 24px;
        margin-top: 40px;
      }
      p {
        color: #9298ac;
        font-size: 14px;
        font-weight: bold;

        margin: 20px auto;
        &.preferred-date {
          font-family: AvenirNext, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: #060c2b;
          margin-top: 20px;

          span {
            color: #97d7d8;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
const OfferWrapper = styled.div`
  width: 50%;
  background-color: #f3fbff;
  padding: 30px;
  border-radius: 0 18px 18px 0;
  cursor: pointer;
  .quotations {
    &__file {
      margin-bottom: 50px;
      & > div,
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
        border: solid 1px #e4e9f2;
        background-color: #ffffff;
        padding: 12px 15px;
        color: #97d7d8;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        p {
          margin: 0;
        }
        div {
          display: flex;
          align-items: center;
          p {
            margin-left: 10px;
          }
        }
      }
    }
    &__details {
      div {
        display: flex;
        p {
          width: 50%;
          font-family: AvenirNext, Arial, sans-serif;
          font-size: 16.8px;
          color: #042c5c;
        }
      }
    }
    &__note {
      width: 100%;
      display: block;
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      color: #060c2b;
      margin: 0;
    }
    &__canceled {
      width: 100%;
      padding: 15px;
      box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
      border: solid 1px #e4e9f2;
      background-color: #ec0707;
      text-align: center;
      .canceled {
        color: #fff;
        font-family: AvenirNext;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        width: 100%;
      }
    }
  }
`;

export default class BookedRequest extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
  };

  render() {
    const { request } = this.props;

    const startDate = convertDateToFullStringDate(request.createdAt);

    const quotations = request && request.hospitalReservationQuotation[0];
    return (
      <BookedRequestWrapper
        onClick={() =>
          history.push(`/request/${request.id}?patientId=${request.patient.id}`)
        }
      >
        <PatientWrapper>
          <div className="patient__img">
            <ProfilePic user={request.patient} width="50" height="50" />
            <p>
              {request.patient.firstName} {request.patient.middleName}{' '}
              {request.patient.lastName}
            </p>
          </div>
          <div className="patient__content">
            <H2>{request.note}</H2>
            <p>Sent on: {startDate}</p>
            <p className="preferred-date">
              Booking confirmed Check In{' '}
              <span>
                {moment
                  .unix(quotations.earliestCheckInTime)
                  .format('MM-DD-YYYY')}
              </span>
            </p>
          </div>
        </PatientWrapper>
        <OfferWrapper>
          {quotations && (
            <>
              <div className="quotations__file">
                {quotations.fileLocation ? (
                  <button
                    onClick={(event) => {
                      window.open(quotations.fileLocation, '_blank');
                      event.stopPropagation();
                    }}
                  >
                    <div>
                      <img src={NoteIcon} alt="note Icon" />
                      <p>
                        {quotations.fileName
                          .replaceAll('.pdf', '')
                          .substring(0, 20)}
                      </p>
                    </div>
                    <div>
                      <p>{quotations.price} GBP</p>
                    </div>
                  </button>
                ) : (
                  <div>
                    <div>
                      <img src={NoteIcon} alt="note Icon" />
                      <p>Quotation Price</p>
                    </div>
                    <div>
                      <p>{quotations.price} GBP</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="quotations__details">
                <div>
                  <p>Duration</p>
                  <p>{quotations.durationInDays} days</p>
                </div>
                <p className="quotations__note">{quotations.note}</p>
                {quotations.status === 'canceled' && (
                  <div className="quotations__canceled">
                    <p className="canceled">Canceled</p>
                  </div>
                )}
              </div>
            </>
          )}
        </OfferWrapper>
      </BookedRequestWrapper>
    );
  }
}
