import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import RadiologistComponents from './Radiologist';

function RadiologistPage(props) {
  return (
    <MainLayout
      footer="main"
      children={<RadiologistComponents {...props} />}
      {...props}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(RadiologistPage);
