import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { patientReportsActions } from 'redux/actions';
import { Breadcrumb, H1, Pagination, StateCard } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import styled from '@emotion/styled/macro';

const ConditionsWrapper = styled.div`
  h1 {
    font-size: 30px;
  }
  a {
    font-family: AvenirNext, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #9298ac;
  }
  .ant-breadcrumb-separator {
    margin-right: 5px;
    color: #9298ac;
  }
  .add {
    margin-bottom: 25px;
  }
  .pagination {
    max-width: 780px;
    margin-left: 0;
  }
  .request {
    display: block;
    margin: 15px 0;
    color: #97d7d8;
    img {
      margin-right: 10px;
      width: 25px;
      height: 25px;
      margin-top: 20px;
      position: relative;
      top: -11px;
    }
  }
`;

class Conditions extends Component {
  componentDidMount() {
    const {
      getSharedConditions,
      match: {
        params: { patientId },
      },
    } = this.props;

    if (patientId) {
      getSharedConditions(patientId);
    }
  }

  setPage = (page) => {
    const {
      match: {
        params: { patientId },
      },
      getSharedConditions,
    } = this.props;
    if (patientId) {
      getSharedConditions(patientId, null, page);
    }
  };

  render() {
    const {
      reports: conditions,
      match: {
        params: { requestId, patientId, invitationId },
        path,
      },
    } = this.props;
    const BreadcrumbLinks =
      requestId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
          ]
        : invitationId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
          ]
        : [];
    const url = (reportId) =>
      requestId && path.includes('/request')
        ? `/request/${requestId}/condition/${reportId}?patientId=${patientId}`
        : invitationId && path.includes('/invited-patient')
        ? `/invited-patient/${invitationId}/condition/${reportId}?patientId=${patientId}`
        : `#!`;
    return (
      <Container>
        <ConditionsWrapper>
          <Breadcrumb links={BreadcrumbLinks} />
          <H1>Pre-existing Conditions</H1>

          {conditions &&
            conditions.data &&
            conditions.data.map((condition) => (
              <StateCard
                to={url(condition.id)}
                key={condition.createdAt}
                title={condition.value}
                time={condition.createdAt}
              />
            ))}

          {conditions &&
            conditions.meta &&
            conditions.meta?.total > conditions.meta?.per_page && (
              <Pagination
                pageSize={conditions.meta?.per_page}
                total={conditions.meta?.total}
                setPage={this.setPage}
              />
            )}
        </ConditionsWrapper>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  const { reports } = state.patientReports;
  return {
    reports,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSharedConditions: (id, sharedToken = null, page = null) =>
    dispatch(patientReportsActions.getSharedConditions(id, sharedToken, page)),
});

Conditions.propTypes = {
  reports: PropTypes.object,
  match: PropTypes.object.isRequired,
  getSharedConditions: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
