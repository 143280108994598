import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { invitePatientActions } from 'redux/actions';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import styled from '@emotion/styled';
import { H1, PrimaryButton, Pagination, StateCard } from '@mous221/hk-utils';
import { Container } from '@mous221/hk-components';
import { default as EmptyPatient } from './EmptyPatient';

const HospitalRequestsWrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  .ant-spin-nested-loading {
    width: 100%;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ListItemsWrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  display: block;
  padding: 20px;
  & > div {
    max-width: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
`;
export class HospitalRequests extends Component {
  componentDidMount() {
    const { getInvitedPatient } = this.props;
    getInvitedPatient();
  }
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    getInvitedPatient: PropTypes.func.isRequired,
    invitedPatient: PropTypes.object.isRequired,
  };

  render() {
    const { invitedPatient, loading } = this.props;
    return (
      <Container>
        <HeaderWrapper>
          <H1>Hospital Requests</H1>
          <PrimaryButton>
            <Link to="/invite-patient">Invite Patient</Link>
          </PrimaryButton>
        </HeaderWrapper>

        <HospitalRequestsWrapper>
          <Spin spinning={loading}>
            {invitedPatient.data?.length === 0 ? (
              <EmptyPatient />
            ) : (
              <ListItemsWrapper>
                {invitedPatient &&
                  invitedPatient.data
                    .reverse()
                    .map((report) => (
                      <StateCard
                        to={`/invited-patient/${report.id}`}
                        title={report.name}
                        time={report.createdAt}
                        key={report.id}
                      />
                    ))}
                {invitedPatient &&
                  invitedPatient.meta.total > invitedPatient.meta.per_page && (
                    <Pagination
                      pageSize={invitedPatient.meta.per_page}
                      total={invitedPatient.meta.total}
                      setPage={this.setPage}
                    />
                  )}
              </ListItemsWrapper>
            )}
          </Spin>
        </HospitalRequestsWrapper>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { invitedPatient, loading } = state.invitePatient;
  return {
    invitedPatient,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getInvitedPatient: () => dispatch(invitePatientActions.getInvitedPatient()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HospitalRequests);
