import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';
import { Container, MedicalReport } from '@mous221/hk-components';
class LabResult extends Component {
  componentDidMount() {
    const {
      getLabResult,
      match: {
        params: { id },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );
    if (id && patientId) {
      getLabResult(id, patientId);
    }
  }
  render() {
    const {
      report: labResult,
      match: {
        params: { requestId, invitationId, id },
        path,
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      requestId && patientId && path.includes('/request')
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
            {
              item: 'Lab Results',
              url: `/request/${requestId}/lab-results/${patientId}`,
            },
          ]
        : invitationId && patientId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
            {
              item: 'Lab Results',
              url: `/invited-patient/${invitationId}/lab-results/${patientId}`,
            },
          ]
        : [];

    return (
      <Container>
        {labResult && labResult.id === Number(id) && (
          <MedicalReport
            title={labResult.title}
            updatedAt={labResult.updatedAt}
            description={labResult.note || labResult.description}
            image={{
              fileLocation: labResult.fileLocation,
              fileName: labResult.fileName,
            }}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={requestId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLabResult: (id, patientId) =>
    dispatch(patientReportsActions.getLabResult(id, patientId)),
});

LabResult.propTypes = {
  getLabResult: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LabResult);
