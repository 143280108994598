import { combineReducers } from 'redux';
import { default as alert } from './alerts/alertsReducer';
import { default as authentication } from './user/userReducer';
import { default as patient } from './patient/patientReducer';
import { default as patientReports } from './patientReports/patientReportsReducer';
import { default as invitePatient } from './invitePatient/invitePatientReducer';

const appReducer = combineReducers({
  alert,
  authentication,
  patient,
  patientReports,
  invitePatient,
});

export default appReducer;
