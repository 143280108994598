import React, { Component } from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';

import {
  Medical2X,
  Medical,
  Radiology2X,
  Radiology,
  XRay,
  XRay2X,
  Ecg,
  Ecg2X,
  Algro,
  Algro2X,
  Lab,
  Lab2X,
} from 'images';
import { convertDateToFullStringDate } from '@mous221/hk-utils';
const MedicalRecordWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  .medical-record__title {
    font-size: 20px;
    font-weight: bold;
    color: #060c2b;
  }
  .ant-row {
    .ant-col {
      &:first-of-type > div {
        margin-right: auto;
      }
      &:nth-of-type(2) > div {
        margin: auto;
      }
      &:last-child > div {
        margin-left: auto;
      }
    }
  }
`;

const Record = styled.div`
  width: 95%;
  background #fff;
  height: 170px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  position: relative;
  
  & > div {
    &::before  {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: #f9fafc;
      left: 0;
      top: 0;
      z-index: 2;
    }
    a {
      position: relative;
      z-index: 3;
      color: #97d7d8;
      font-size: 12px;
    }
  }
  .record {
    &__image {
      margin-bottom: 10px;
    }
    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #97d7d8;
    }
    &__time {
      font-size: 12px;
      font-weight: normal;
      color: #9298ac;
    }
  }
`;
export default class MedicalRecord extends Component {
  render() {
    const {
      medicalReport,
      allergy,
      condition,
      ecg,
      labResult,
      radiologies,
      xrays,
      patientId,
      requestId,
      loading,
    } = this.props;

    return (
      <Spin spinning={loading}>
        <MedicalRecordWrapper>
          <h1 className="medical-record__title">Medical Record</h1>
          <Row>
            <Col span={8}>
              <Record>
                {condition && condition.length > 0 ? (
                  <Link to={`/request/${requestId}/conditions/${patientId}`}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="xray" />
                      </picture>
                    </div>
                    <h3 className="record__title">Conditions</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        condition[condition.length - 1].updatedAt
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="xray" />
                      </picture>
                    </div>
                    <h3 className="record__title">Conditions</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {medicalReport && medicalReport.length > 0 ? (
                  <Link
                    to={`/request/${requestId}/medical-reports/${patientId}`}
                  >
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Medical2X} 2x`} />
                        <img src={Medical} alt="Medical" />
                      </picture>
                    </div>
                    <h3 className="record__title">Medical Report</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        medicalReport[medicalReport.length - 1].updatedAt
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Medical2X} 2x`} />
                        <img src={Medical} alt="Medical" />
                      </picture>
                    </div>
                    <h3 className="record__title">Medical Report</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {labResult && labResult.length > 0 ? (
                  <Link to={`/request/${requestId}/lab-results/${patientId}`}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Lab2X} 2x`} />
                        <img src={Lab} alt="Lab" />
                      </picture>
                    </div>
                    <h3 className="record__title">Lab Result</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        labResult[labResult.length - 1].updatedAt
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Lab2X} 2x`} />
                        <img src={Lab} alt="Lab" />
                      </picture>
                    </div>
                    <h3 className="record__title">Lab Result</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Record>
                {allergy && allergy.length > 0 ? (
                  <Link to={`/request/${requestId}/allergies/${patientId}`}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Algro2X} 2x`} />
                        <img src={Algro} alt="Allergies" />
                      </picture>
                    </div>
                    <h3 className="record__title">Allergies</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        allergy[allergy.length - 1].updatedAt
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Algro2X} 2x`} />
                        <img src={Algro} alt="Allergies" />
                      </picture>
                    </div>
                    <h3 className="record__title">Allergies</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {radiologies && radiologies.length > 0 ? (
                  <Link to={`/request/${requestId}/radiologies/${patientId}`}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Radiology2X} 2x`} />
                        <img src={Radiology} alt="Radiology" />
                      </picture>
                    </div>
                    <h3 className="record__title">MRI / CT Scan</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        radiologies[radiologies.length - 1].updated_at
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Radiology2X} 2x`} />
                        <img src={Radiology} alt="Radiology" />
                      </picture>
                    </div>
                    <h3 className="record__title">MRI / CT Scan</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
            <Col span={8}>
              <Record>
                {xrays && xrays.length > 0 ? (
                  <Link to={`/request/${requestId}/xrays/${patientId}`}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="XRay" />
                      </picture>
                    </div>
                    <h3 className="record__title">XRays</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        xrays[xrays.length - 1].updatedAt
                      )}
                    </p>
                  </Link>
                ) : (
                  <div>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${XRay2X} 2x`} />
                        <img src={XRay} alt="XRay" />
                      </picture>
                    </div>
                    <h3 className="record__title">XRay</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Record>
                {ecg && ecg.length > 0 ? (
                  <Link to={`/request/${requestId}/ecgs/${patientId}`}>
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Ecg2X} 2x`} />
                        <img src={Ecg} alt="ECG" />
                      </picture>
                    </div>
                    <h3 className="record__title">ECG</h3>
                    <p className="record__time">
                      {convertDateToFullStringDate(
                        ecg[ecg.length - 1].updatedAt
                      )}
                    </p>
                  </Link>
                ) : (
                  <div to="#!">
                    <div className="record__image">
                      <picture>
                        <source srcSet={`${Ecg2X} 2x`} />
                        <img src={Ecg} alt="ECG" />
                      </picture>
                    </div>
                    <h3 className="record__title">ECG</h3>
                    <p className="record__time">Unavailable</p>
                  </div>
                )}
              </Record>
            </Col>
          </Row>
        </MedicalRecordWrapper>
      </Spin>
    );
  }
}
MedicalRecord.propTypes = {
  medicalReport: PropTypes.array.isRequired,
  allergy: PropTypes.array.isRequired,
  condition: PropTypes.array.isRequired,
  ecg: PropTypes.array.isRequired,
  labResult: PropTypes.array.isRequired,
  radiology: PropTypes.object,
  radiologies: PropTypes.array.isRequired,
  xrays: PropTypes.array.isRequired,
  patientId: PropTypes.number,
  requestId: PropTypes.number,
  loading: PropTypes.bool.isRequired,
};
