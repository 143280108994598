import { alertActions } from 'redux/actions';
import { patientReportsServices } from 'services';
import patientReportsConstants from './types';

const getSharedXRays = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedXRaysRequest());
    patientReportsServices
      .getSharedXRays(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedXRaysFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedXRaysSuccess(res.xrays));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getXRay = (id, patientId) => {
  return (dispatch) => {
    dispatch(getXRayRequest());
    patientReportsServices.getXRay(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getXRayFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getXRaySuccess(res.xray));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getXRayRequest = () => ({
  type: patientReportsConstants.GET_XRAY_REQUEST,
});
const getXRaySuccess = (xray) => ({
  type: patientReportsConstants.GET_XRAY_SUCCESS,
  xray,
});
const getXRayFailure = (error) => ({
  type: patientReportsConstants.GET_XRAY_FAILURE,
  error,
});

const getSharedXRaysRequest = () => ({
  type: patientReportsConstants.GET_SHARED_XRAYS_REQUEST,
});
const getSharedXRaysSuccess = (xrays) => ({
  type: patientReportsConstants.GET_SHARED_XRAYS_SUCCESS,
  xrays,
});
const getSharedXRaysFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_XRAYS_FAILURE,
  error,
});

const getMRI = (id, patientId) => {
  return (dispatch) => {
    dispatch(getMRIRequest());
    patientReportsServices.getMRI(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getMRIFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMRISuccess(res.mri));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedMRIs = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedMRIsRequest());
    patientReportsServices
      .getSharedMRIs(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedMRIsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedMRIsSuccess(res.mris));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getMRIRequest = () => ({
  type: patientReportsConstants.GET_MRI_REQUEST,
});
const getMRISuccess = (mri) => ({
  type: patientReportsConstants.GET_MRI_SUCCESS,
  mri,
});
const getMRIFailure = (error) => ({
  type: patientReportsConstants.GET_MRI_FAILURE,
  error,
});

const getSharedMRIsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_MRIS_REQUEST,
});
const getSharedMRIsSuccess = (mris) => ({
  type: patientReportsConstants.GET_SHARED_MRIS_SUCCESS,
  mris,
});
const getSharedMRIsFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_MRIS_FAILURE,
  error,
});

const getCT = (id, patientId) => {
  return (dispatch) => {
    dispatch(getCTRequest());
    patientReportsServices.getCT(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getCTFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getCTSuccess(res.ct));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedCTs = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedCTsRequest());
    patientReportsServices
      .getSharedCTs(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedCTsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedCTsSuccess(res.cts));
          dispatch(alertActions.clear());
        }
      });
  };
};
const getCTRequest = () => ({
  type: patientReportsConstants.GET_CT_REQUEST,
});
const getCTSuccess = (ct) => ({
  type: patientReportsConstants.GET_CT_SUCCESS,
  ct,
});
const getCTFailure = (error) => ({
  type: patientReportsConstants.GET_CT_FAILURE,
  error,
});

const getSharedCTsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_CTS_REQUEST,
});
const getSharedCTsSuccess = (cts) => ({
  type: patientReportsConstants.GET_SHARED_CTS_SUCCESS,
  cts,
});
const getSharedCTsFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_CTS_FAILURE,
  error,
});

const getAllergy = (id, patientId) => {
  return (dispatch) => {
    dispatch(getAllergyRequest());
    patientReportsServices.getAllergy(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getAllergyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getAllergySuccess(res.allergy));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedAllergies = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedAllergiesRequest());
    patientReportsServices
      .getSharedAllergies(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedAllergiesFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedAllergiesSuccess(res.allergies));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getAllergyRequest = () => ({
  type: patientReportsConstants.GET_ALLERGY_REQUEST,
});
const getAllergySuccess = (allergy) => ({
  type: patientReportsConstants.GET_ALLERGY_SUCCESS,
  allergy,
});
const getAllergyFailure = (error) => ({
  type: patientReportsConstants.GET_ALLERGY_FAILURE,
  error,
});

const getSharedAllergiesRequest = () => ({
  type: patientReportsConstants.GET_SHARED_ALLERGIES_REQUEST,
});
const getSharedAllergiesSuccess = (allergies) => ({
  type: patientReportsConstants.GET_SHARED_ALLERGIES_SUCCESS,
  allergies,
});
const getSharedAllergiesFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_ALLERGIES_FAILURE,
  error,
});

const getCondition = (id, patientId) => {
  return (dispatch) => {
    dispatch(getConditionRequest());
    patientReportsServices.getCondition(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getConditionFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getConditionSuccess(res.condition));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedConditions = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedConditionsRequest());
    patientReportsServices
      .getSharedConditions(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedConditionsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedConditionsSuccess(res.conditions));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getConditionRequest = () => ({
  type: patientReportsConstants.GET_CONDITION_REQUEST,
});
const getConditionSuccess = (condition) => ({
  type: patientReportsConstants.GET_CONDITION_SUCCESS,
  condition,
});
const getConditionFailure = (error) => ({
  type: patientReportsConstants.GET_CONDITION_FAILURE,
  error,
});

const getSharedConditionsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_CONDITIONS_REQUEST,
});
const getSharedConditionsSuccess = (conditions) => ({
  type: patientReportsConstants.GET_SHARED_CONDITIONS_SUCCESS,
  conditions,
});
const getSharedConditionsFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_CONDITIONS_FAILURE,
  error,
});

const getECG = (id, patientId) => {
  return (dispatch) => {
    dispatch(getECGRequest());
    patientReportsServices.getECG(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getECGFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getECGSuccess(res.ecg));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedECGs = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedECGsRequest());
    patientReportsServices
      .getSharedECGs(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedECGsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedECGsSuccess(res.ecgs));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getECGRequest = () => ({
  type: patientReportsConstants.GET_ECG_REQUEST,
});

const getECGSuccess = (ecg) => ({
  type: patientReportsConstants.GET_ECG_SUCCESS,
  ecg,
});

const getECGFailure = (error) => ({
  type: patientReportsConstants.GET_ECG_FAILURE,
  error,
});

const getSharedECGsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_ECGS_REQUEST,
});

const getSharedECGsSuccess = (ecgs) => ({
  type: patientReportsConstants.GET_SHARED_ECGS_SUCCESS,
  ecgs,
});

const getSharedECGsFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_ECGS_FAILURE,
  error,
});

const getLabResult = (id, patientId) => {
  return (dispatch) => {
    dispatch(getLabResultRequest());
    patientReportsServices.getLabResult(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getLabResultFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getLabResultSuccess(res.labResult));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedLabResults = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedLabResultsRequest());
    patientReportsServices
      .getSharedLabResults(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedLabResultsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedLabResultsSuccess(res.labResults));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getLabResultRequest = () => ({
  type: patientReportsConstants.GET_LAB_RESULT_REQUEST,
});

const getLabResultSuccess = (labResult) => ({
  type: patientReportsConstants.GET_LAB_RESULT_SUCCESS,
  labResult,
});

const getLabResultFailure = (error) => ({
  type: patientReportsConstants.GET_LAB_RESULT_FAILURE,
  error,
});

const getSharedLabResultsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_LAB_RESULTS_REQUEST,
});

const getSharedLabResultsSuccess = (labResults) => ({
  type: patientReportsConstants.GET_SHARED_LAB_RESULTS_SUCCESS,
  labResults,
});

const getSharedLabResultsFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_LAB_RESULTS_FAILURE,
  error,
});

const getMedicalReport = (id, patientId) => {
  return (dispatch) => {
    dispatch(getMedicalReportRequest());
    patientReportsServices.getMedicalReport(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getMedicalReportFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getMedicalReportSuccess(res.medicalReport));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedMedicalReports = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedMedicalReportsRequest());
    patientReportsServices
      .getSharedMedicalReports(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedMedicalReportsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedMedicalReportsSuccess(res.medicalReports));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getMedicalReportRequest = () => ({
  type: patientReportsConstants.GET_REPORT_REQUEST,
});
const getMedicalReportSuccess = (medicalReport) => ({
  type: patientReportsConstants.GET_REPORT_SUCCESS,
  medicalReport,
});
const getMedicalReportFailure = (error) => ({
  type: patientReportsConstants.GET_REPORT_FAILURE,
  error,
});

const getSharedMedicalReportsRequest = () => ({
  type: patientReportsConstants.GET_SHARED_REPORTS_REQUEST,
});
const getSharedMedicalReportsSuccess = (medicalReports) => ({
  type: patientReportsConstants.GET_SHARED_REPORTS_SUCCESS,
  medicalReports,
});
const getSharedMedicalReportsFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_REPORTS_FAILURE,
  error,
});

const getRadiology = (id, patientId) => {
  return (dispatch) => {
    dispatch(getRadiologyRequest());
    patientReportsServices.getRadiology(id, patientId).then((res) => {
      if (res.error) {
        dispatch(getRadiologyFailure(res.errors));
        dispatch(alertActions.error(res.errors || 'there is something wrong'));
        dispatch(alertActions.clear());
      } else {
        dispatch(getRadiologySuccess(res.radiology));
        dispatch(alertActions.clear());
      }
    });
  };
};

const getSharedRadiologies = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getSharedRadiologiesRequest());
    patientReportsServices
      .getSharedRadiologies(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getSharedRadiologiesFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getSharedRadiologiesSuccess(res.radiologies));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getRadiologyRequest = () => ({
  type: patientReportsConstants.GET_RADIOLOGY_REQUEST,
});
const getRadiologySuccess = (radiology) => ({
  type: patientReportsConstants.GET_RADIOLOGY_SUCCESS,
  radiology,
});
const getRadiologyFailure = (error) => ({
  type: patientReportsConstants.GET_RADIOLOGY_FAILURE,
  error,
});

const getSharedRadiologiesRequest = () => ({
  type: patientReportsConstants.GET_SHARED_RADIOLOGIES_REQUEST,
});
const getSharedRadiologiesSuccess = (radiologies) => ({
  type: patientReportsConstants.GET_SHARED_RADIOLOGIES_SUCCESS,
  radiologies,
});
const getSharedRadiologiesFailure = (error) => ({
  type: patientReportsConstants.GET_SHARED_RADIOLOGIES_FAILURE,
  error,
});

const getReadings = (id, informationShareToken = null, page) => {
  return (dispatch) => {
    dispatch(getReadingsRequest());
    patientReportsServices
      .getReadings(id, informationShareToken, page)
      .then((res) => {
        if (res.error) {
          dispatch(getReadingsFailure(res.errors));
          dispatch(
            alertActions.error(res.errors || 'there is something wrong')
          );
          dispatch(alertActions.clear());
        } else {
          dispatch(getReadingsSuccess(res.readings));
          dispatch(alertActions.clear());
        }
      });
  };
};

const getReadingsRequest = () => ({
  type: patientReportsConstants.GET_PATIENT_READING_REQUEST,
});
const getReadingsSuccess = (readings) => ({
  type: patientReportsConstants.GET_PATIENT_READING_SUCCESS,
  readings,
});
const getReadingsFailure = (error) => ({
  type: patientReportsConstants.GET_PATIENT_READING_FAILURE,
  error,
});

const clearData = () => ({
  type: patientReportsConstants.CLEAR_DATA,
});

const patientReportsActions = {
  getSharedXRays,
  getXRay,
  getMRI,
  getSharedMRIs,
  getCT,
  getSharedCTs,
  getAllergy,
  getSharedAllergies,
  getCondition,
  getSharedConditions,
  getECG,
  getSharedECGs,
  getLabResult,
  getSharedLabResults,
  getMedicalReport,
  getSharedMedicalReports,
  getReadings,
  clearData,
  getRadiology,
  getSharedRadiologies,
};

export default patientReportsActions;
