import React from 'react';
import { connect } from 'react-redux';
import { userActions } from 'redux/actions';
import { MainLayout } from '@mous221/hk-components';
import MedicalReportComponents from './MedicalReport';

function MedicalReportPage(props) {
  return (
    <MainLayout
      footer="main"
      children={<MedicalReportComponents {...props} />}
      {...props}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(userActions.logout()),
});

export default connect(() => ({}), mapDispatchToProps)(MedicalReportPage);
