import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { patientActions } from 'redux/actions';
import {
  H2,
  ProfilePic,
  PrimaryButton,
  convertDateToFullStringDate,
} from '@mous221/hk-utils';
import styled from '@emotion/styled';
import { NoteIcon } from 'images';

const ViewQuotationsWrapper = styled.div`
  background: #fff;

  border-radius: 18px;
  box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
  border: solid 1px #e4e9f2;
  margin: 0 15px;
  margin-bottom: 20px;
  display: flex;
`;

const PatientWrapper = styled.div`
  width: 50%;
  padding: 30px;
  border-radius: 18px 0 0 18px;
  .patient {
    &__img {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 10px;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 16px;
        font-weight: bold;
      }
    }
    &__content {
      margin-top: 20px;
      h2 {
        font-size: 24px;
        margin-top: 40px;
      }
      p {
        color: #9298ac;
        font-size: 14px;
        font-weight: bold;

        margin: 20px auto;
        &.preferred-date {
          font-family: AvenirNext, Arial, sans-serif;
          font-size: 16px;
          font-weight: 500;
          color: #060c2b;
          margin-top: 20px;
          margin-bottom: 40px;
          span {
            color: #97d7d8;
            font-weight: bold;
          }
        }
      }
    }
  }
`;
const OfferWrapper = styled.div`
  width: 50%;
  background-color: #f3fbff;
  padding: 30px;
  border-radius: 0 18px 18px 0;
  position: relative;
  .quotations {
    &__file {
      margin-top: 30px;
      margin-bottom: 50px;
      & > div,
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
        border: solid 1px #e4e9f2;
        background-color: #ffffff;
        padding: 12px 15px;
        color: #97d7d8;
        font-family: AvenirNext, Arial, sans-serif;
        font-size: 14px;
        font-weight: 500;
        p {
          margin: 0;
        }
        div {
          display: flex;
          align-items: center;
          p {
            margin-left: 10px;
          }
        }
      }
    }
    &__details {
      div {
        display: flex;
        p {
          width: 50%;
          font-family: AvenirNext, Arial, sans-serif;
          font-size: 16.8px;
          color: #042c5c;
        }
      }
    }
    &__note {
      width: 100%;
      display: block;
      font-family: AvenirNext, Arial, sans-serif;
      font-size: 14px;
      color: #060c2b;
      margin: 0;
    }
    &__canceled {
      width: 100%;
      padding: 15px;
      box-shadow: 1px 2px 3px 2px rgba(1, 2, 2, 0.02);
      border: solid 1px #e4e9f2;
      background-color: #ec0707;
      text-align: center;
      .canceled {
        color: #fff;
        font-family: AvenirNext;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        width: 100%;
      }
    }
    &__booked {
      width: 100%;
      text-align: right;
      position: absolute;
      right: 30px;
      bottom: 40px;
      .booked {
        color: #97d7d8;
        font-family: AvenirNext;
        font-size: 20px;
        font-weight: bold;

        margin: 0;
        width: 100%;
      }
    }
  }
`;

export class ViewQuotations extends Component {
  static propTypes = {
    request: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    generateShareToken: PropTypes.func.isRequired,
    readings: PropTypes.array,
  };

  render() {
    const { request, generateShareToken, readings } = this.props;
    console.log('request.preferredCheckInDate');
    console.log(request.preferredCheckInDate);
    const startDate = convertDateToFullStringDate(request.createdAt);
    const preferredDate = request.preferredCheckInDate
      ? moment(new Date(request.preferredCheckInDate * 1000)).format(
          'MMM DD, YYYY'
        )
      : 'No preferred check in';
    const quotations = request && request.hospitalReservationQuotation[0];

    return (
      <ViewQuotationsWrapper>
        <PatientWrapper>
          <div className="patient__img">
            <ProfilePic user={request.patient} width="50" height="50" />
            <p>
              {request.patient.firstName} {request.patient.middleName}{' '}
              {request.patient.lastName}
            </p>
          </div>
          <div className="patient__content">
            <H2>{request.note}</H2>
            <p>Sent on: {startDate}</p>
            <p className="preferred-date">
              Preferred Check In date: <span>{preferredDate}</span>
            </p>
            <PrimaryButton
              onClick={() => {
                generateShareToken(request.patient.id, {
                  patient: request.patient,
                  readings: readings,
                  reservationTitle: request.note,
                  consultation: request.patientConsultationRequest,
                });
              }}
            >
              <button>Share with designated team</button>
            </PrimaryButton>
          </div>
        </PatientWrapper>
        <OfferWrapper>
          <H2>Offer Details </H2>
          {quotations && (
            <>
              <div className="quotations__file">
                {quotations.fileLocation ? (
                  <a
                    href={quotations.fileLocation}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div>
                      <img src={NoteIcon} alt="note Icon" />
                      <p>{quotations.fileName}</p>
                    </div>
                    <div>
                      <p>{quotations.price} GBP</p>
                    </div>
                  </a>
                ) : (
                  <div>
                    <div>
                      <img src={NoteIcon} alt="note Icon" />
                      <p>Quotation Price</p>
                    </div>
                    <div>
                      <p>{quotations.price} GBP</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="quotations__details">
                <div>
                  <p>
                    {quotations.status === 'booked'
                      ? 'Check In'
                      : 'Earliest Check In'}
                  </p>
                  <p>
                    {moment
                      .unix(quotations.earliestCheckInTime)
                      .format('MM-DD-YYYY')}
                  </p>
                </div>
                <div>
                  <p>Duration</p>
                  <p>{quotations.durationInDays} days</p>
                </div>
                <p className="quotations__note">{quotations.note}</p>
                {quotations.status === 'canceled' && (
                  <div className="quotations__canceled">
                    <p className="canceled">Canceled</p>
                  </div>
                )}
                {quotations.status === 'booked' && (
                  <div className="quotations__booked">
                    <p className="booked">booked</p>
                  </div>
                )}
              </div>
            </>
          )}
        </OfferWrapper>
      </ViewQuotationsWrapper>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  generateShareToken: (id, sharedDate) =>
    dispatch(patientActions.generateShareToken(id, sharedDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuotations);
