import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { patientReportsActions } from 'redux/actions';

import { Container, MedicalReport } from '@mous221/hk-components';
class Allergy extends Component {
  componentDidMount() {
    const {
      getAllergy,
      match: {
        params: { id },
      },
    } = this.props;
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    if (id && patientId) {
      getAllergy(id, patientId);
    }
  }
  render() {
    const {
      report: allergy,
      match: {
        params: { requestId, invitationId, id },
        path,
        ...res
      },
    } = this.props;
    console.log('res', res);
    const patientId = new URLSearchParams(this.props.location.search).get(
      'patientId'
    );

    const BreadcrumbLinks =
      requestId && patientId
        ? [
            {
              item: 'Medical Record ',
              url: `/request/${requestId}?patientId=${patientId}`,
            },
            {
              item: 'Allergies',
              url: `/request/${requestId}/allergies/${patientId}`,
            },
          ]
        : invitationId && patientId && path.includes('/invited-patient')
        ? [
            {
              item: 'Medical Record ',
              url: `/invited-patient/${invitationId}?patientId=${patientId}`,
            },
            {
              item: 'Allergies',
              url: `/invited-patient/${invitationId}/allergies/${patientId}`,
            },
          ]
        : [];

    return (
      <Container>
        {allergy && allergy.id === Number(id) && (
          <MedicalReport
            title={allergy.value}
            updatedAt={allergy.updatedAt}
            description={allergy.description || allergy.note}
            BreadcrumbLinks={BreadcrumbLinks}
            consultationId={requestId}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { report } = state.patientReports;
  return {
    report,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllergy: (id, patientId) =>
    dispatch(patientReportsActions.getAllergy(id, patientId)),
});

Allergy.propTypes = {
  getAllergy: PropTypes.func.isRequired,
  report: PropTypes.object,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Allergy);
