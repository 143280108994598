import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patientActions } from 'redux/actions';
import styled from '@emotion/styled/macro';

import { Container } from '@mous221/hk-components';
import { NewRequests } from './';
import { Spin } from 'antd';

const HomeWrapper = styled.div`
  max-width: 900px;
  margin: auto;
  margin-top: 60px;
  .home-content,
  .home-sidebar {
    padding: 0 15px;
  }
`;

class Home extends Component {
  componentDidMount() {
    const { getNewRequests } = this.props;
    getNewRequests();
  }
  static propTypes = {
    newRequests: PropTypes.object,
    getNewRequests: PropTypes.func.isRequired,
    rejectRequest: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  render() {
    const { newRequests, rejectRequest, loading } = this.props;

    return (
      <>
        <Container>
          <HomeWrapper>
            <Spin spinning={loading}>
              <NewRequests
                requests={newRequests && newRequests}
                rejectRequest={rejectRequest}
                loading={loading}
              />
            </Spin>
          </HomeWrapper>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, newRequests } = state.patient;
  return {
    loading,
    newRequests,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNewRequests: () => dispatch(patientActions.getNewRequests()),
  rejectRequest: (id) => dispatch(patientActions.rejectRequest(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
